import React from 'react'

const Error = () => {

    return (
        <p>Error! Oh no :(</p>
    )

}

export default Error