import React from 'react'

import { Input, Label } from './style';

const TextArea = (props) => {

    return (
        <Label {...props}>
            { props.label &&
                <span>{props.label}</span>
            }
            <Input {...props} />
            {(props.error || props.helper) &&
                <p className="helperText">{ props.error ? props.error : props.helper }</p>
            }
        </Label>
    )

}

export default TextArea