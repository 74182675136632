import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import ButtonLink from '../../../components/ButtonLink'
import Error from '../../../components/Error'
import Heading from '../../../components/Heading'
import Loading from '../../../components/Loading'

import { QUERY_ALL_NEWS } from './graphql'

import { IndexWrapper } from '../style'

const News = () => {

    const { loading, error, data } = useQuery(QUERY_ALL_NEWS);

    let allNews;

    if (loading) {
        allNews = <Loading />
    }
    if (error) {
        allNews= <Error />
    }
    if (data) {
        allNews = data.news.items.map(news => {
            return <div key={news.id}>
                <span>{news.id}</span>
                <span>{news.title}</span>
                <span><Link to={`/admin/news/${news.slug}`}>Edit</Link></span>
            </div>
        })
    }

    return (
        <IndexWrapper>
            <Heading size='1'>Index of all news</Heading>
            <div id='createNew'>
                <ButtonLink to='/admin/news/new'>Create new news item</ButtonLink>
            </div>
            <div id='itemList'>
                {allNews}
            </div>
        </IndexWrapper>
    )
}

export default News