import styled from 'styled-components'

export const WowWrapper = styled.div`
    text-align: left;

    > section {

        background-color: #EEE;
        margin-bottom: 1rem;
        padding: 1rem;

        > h4 {
            margin: 0;
            text-align: center;
        }

        > ul {

            list-style: none;
            margin-bottom: 0;
            padding: 0;

            li {
                display: flex;
                margin-bottom: 0.5rem;
                strong {
                    flex: 0 0 9rem;
                }
            }

        }

    }

`;

export const LowerWow = styled.div`
    align-items: center;
    background: #DBEAC8;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 1rem;
    text-align: center;

    > h4 {
        margin: 0;
    }

    > nav > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        li {
            margin: 0 0.5rem;
        }

    }

`;