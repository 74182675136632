import React from 'react'

import Footer from './Footer'
import Header from './Header'

import { LayoutWrapper } from './style'

const Layout = (props) => {

    return (
        <LayoutWrapper>
            <Header />
            <main>{props.children}</main>
            <Footer />
        </LayoutWrapper>
    )
}

export default Layout;