import styled from 'styled-components'

import { Link } from 'react-router-dom';

export const LinkItem = styled(Link)`
    background: #65A21D;
    border-radius: 5px;
    color: #FFF;
    display: inline-block;
    font-size: ${props => props.size === 'small' ? '0.8rem' : '1rem'};
    padding: ${props => props.size === 'small' ? '0.3rem 0.8rem' : '0.5rem 1rem'};

    :hover {
        background-color: #72BA1C;
        color: #000000;
    }
`;