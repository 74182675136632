import React, { useState } from 'react'

import Button from '../../../components/FormComponents/Button'
import Checkbox from '../../../components/FormComponents/Checkbox'
import Heading from '../../../components/Heading'
import HoldersForm from './AddNewHolder'
import Media from '../../../components/Media'
import MediaForm from './AddNewMedia'
import SubmitButton from '../../../components/FormComponents/SubmitButton'
import TextArea from '../../../components/FormComponents/TextArea'
import TextInput from '../../../components/FormComponents/TextInput'
import ThemesForm from './AddNewTheme'

import { FormWrapper } from './style'

const WowForm = (props) => {

    //console.log(props)

    const handleSubmit = event => {
        event.preventDefault();

        props.updateWow({ variables: {
            slug,
            title,
            content,
            hidden,
            holder_intro,
            holder_template,
            holders,
            themes,
            media
        } });
    }

    const remapHolder = (holder) => {
        //console.log('Received holder is: ', holder);
        const inputInfo = {
            value: holder.info ? holder.info.value : '',
            description: holder.info ? holder.info.description: '',
            held_at: holder.info ? holder.info.held_at : new Date().toISOString().slice(0, 19).replace('T', ' ')
        }
        return {
            slug: holder.slug,
            name: holder.name,
            description: holder.description,
            info: inputInfo
        }
    }
    
    const remapTheme = (theme) => {
        return {
            name: theme.name,
            description: theme.description !== null ? theme.description : '',
            slug: theme.slug
        }
    }
    
    const remapMedia = (media) => {
        return {
            url: media.url,
            file_type: media.file_type,
            title: media.title,
            caption: media.caption
        }
    }

    //Turn objects from query into plain objects (to remove any extra properties added by the query)
    //If props don't exist (new WOW), just create empty object
    const inputHolders = props.holders ? props.holders.map(holder => {
        return remapHolder(holder)
    }) : [];
    const inputThemes = props.themes ? props.themes.map(theme => {
        return remapTheme(theme)
    }) : [];
    const inputMedia = props.media ? props.media.map(media => {
        return {
            url: media.url,
            file_type: media.file_type,
            title: media.title,
            caption: media.caption
        }
    }) : [];

    const [slug, setSlug] = useState(props.slug ? props.slug : '');
    const [title, setTitle] = useState(props.title ? props.title : '');
    const [content, setContent] = useState(props.content ? props.content : '');
    const [hidden, setHidden] = useState(props.hidden ? props.hidden : false);
    const [holder_intro, setHolderIntro] = useState(props.holder_intro);
    const [holder_template, setHolderTemplate] = useState(props.holder_template);
    const [holders, setHolders] = useState(inputHolders);
    const [themes, setThemes] = useState(inputThemes);
    const [media, setMedia] = useState(inputMedia);
    //const [user, setUser] = useState(0);
    const [displayAddHolder, setDisplayAddHolder] = useState(false);
    const [displayAddTheme, setDisplayAddTheme] = useState(false);
    const [displayAddMedia, setDisplayAddMedia] = useState(false);

    const customSetState = (e, fieldName) => {
        //props.fieldErrors[fieldName] = null;

        if (fieldName === 'slug') {
            setSlug(e.target.value);
        } else if (fieldName === 'title') {
            setTitle(e.target.value);
            setSlug(e.target.value.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\d-]/g, ''))
        } else if (fieldName === 'content') {
            setContent(e.target.value);
        } else if (fieldName === 'hidden') {
            setHidden(e.target.checked); //note, checked rather than value
        } else if (fieldName === 'holder_intro') {
            setHolderIntro(e.target.value);
        } else if (fieldName === 'holder_template') {
            setHolderTemplate(e.target.value);
        }
    } 

    /*
     * For updating holder, theme & media states (arrays of objects)
     */
    const holdersSetState = (index, parent = null, e) => {
        const { name, value } = e.target
        // console.log('Holders was:', holders)
        //console.log('Try to set holders:', name, value, parent)
        setHolders(prevState => {
            const stateDup = [...prevState] //Duplicate prevState (so we aren't mutating it directly)
            if(parent) {
                stateDup[index][parent][name] = value //Update the given property
            } else (
                stateDup[index][name] = value //Update the given property
            )
            return stateDup //Set state using the duplicate
        })
    }
    const themesSetState = (index, e) => {
        const { name, value } = e.target
        // console.log('Holders was:', holders)
        //console.log('Try to set holders:', name, value, parent)
        setThemes(prevState => {
            const stateDup = [...prevState] //Duplicate prevState (so we aren't mutating it directly)
            stateDup[index][name] = value //Update the given property
            return stateDup //Set state using the duplicate
        })
    }
    const mediaSetState = (index, e) => {
        const { name, value } = e.target
        setMedia(prevState => {
            const stateDup = [...prevState] //Duplicate prevState (so we aren't mutating it directly)
            stateDup[index][name] = value //Update the given property
            return stateDup //Set state using the duplicate
        })
    }

    const searchBundleForError = (fieldArray) => {
        let returnError = null;
        //Try to match the given fieldArray with the path of each element in the errorBundle
        props.errorBundle.forEach((errorElement) => {
            if(errorElement.name) {
                console.log('Trying to match', errorElement.name, '&', fieldArray);
            }
            if (errorElement.name && errorElement.name.length === fieldArray.length && errorElement.name.every((element, index) => { return element === fieldArray[index] })) {
                returnError = errorElement.msg;
            }
        });
        return returnError;
    }

    /*
     * Holder related handlers
     */
    const handleDisplayNewHolderForm = (e) => {
        e.preventDefault();
        setDisplayAddHolder(!displayAddHolder)
    }
    const handleAddNewHolderToState = (e, newHolder) => {
        e.preventDefault();
        setHolders(prevState => {
            const stateDup = [...prevState] //Duplicate prevState (so we aren't mutating it directly)
            stateDup.push(newHolder)
            return stateDup //Set state using the duplicate
        })
        setDisplayAddHolder(false) //Hide / blank the add holders form, show the add holders button
    }
    const handleRemoveHolder = (index, e) => {
        e.preventDefault();
        if (window.confirm('Are you sure you want to remove this holder?')) {
            setHolders(prevState => {
                const stateDup = [...prevState] //Duplicate prevState (so we aren't mutating it directly)
                stateDup.splice(index, 1)
                return stateDup //Set state using the duplicate
            })
        }
    }

    /*
     * Theme related handlers
     */
    const handleDisplayNewThemeForm = (e) => {
        e.preventDefault();
        setDisplayAddTheme(!displayAddTheme)
    }
    const handleAddNewThemeToState = (e, newTheme) => {
        e.preventDefault();
        setThemes(prevState => {
            const stateDup = [...prevState] //Duplicate prevState (so we aren't mutating it directly)
            stateDup.push(newTheme)
            return stateDup //Set state using the duplicate
        })
        setDisplayAddTheme(false)
    }
    const handleRemoveTheme = (index, e) => {
        e.preventDefault();
        if (window.confirm('Are you sure you want to remove this theme?')) {
            setThemes(prevState => {
                const stateDup = [...prevState] //Duplicate prevState (so we aren't mutating it directly)
                stateDup.splice(index, 1)
                return stateDup //Set state using the duplicate
            })
        }
    }

    /*
     * Media related handlers
     */
    const handleDisplayNewMediaForm = (e) => {
        e.preventDefault();
        setDisplayAddMedia(!displayAddMedia)
    }
    const handleAddNewMediaToState = (newMedia, e) => {
        if (e) { //There might not be an event, since this can be triggered programatically, not by a button click
            e.preventDefault();
        }
        setMedia(prevState => {
            const stateDup = [...prevState] //Duplicate prevState (so we aren't mutating it directly)
            if (!Array.isArray(newMedia)) {
                stateDup.push(newMedia)
            } else {
                stateDup.push(...newMedia) //Handle adding multiple new elements to the state
            }
            return stateDup //Set state using the duplicate
        })
        setDisplayAddMedia(false)
    }
    const handleRemoveMedia = (index, e) => {
        e.preventDefault();
        if (window.confirm('Are you sure you want to remove this media?')) {
            setMedia(prevState => {
                const stateDup = [...prevState] //Duplicate prevState (so we aren't mutating it directly)
                stateDup.splice(index, 1)
                return stateDup //Set state using the duplicate
            })
        }
    }

    return (
        <FormWrapper onSubmit={handleSubmit}>
            {props.generalErrorMsg &&
                <p>Uh oh: {props.generalErrorMsg}!</p>
            }
            <TextInput
                label='Title'
                type="text"
                name='title'
                error={searchBundleForError(['title'])}
                value={title}
                onChange={e => customSetState(e, 'title')}/>
            <TextInput
                label='Slug'
                type="text"
                name='slug'
                error={searchBundleForError(['slug'])}
                value={slug}
                onChange={e => customSetState(e, 'slug')}/>
            <TextArea
                rows='15'
                label='Content'
                name='content'
                error={searchBundleForError(['content'])}
                value={content}
                onChange={e => customSetState(e, 'content')}/>
            <Checkbox
                label='Hidden?'
                name='hidden'
                error={searchBundleForError(['hidden'])}
                checked={hidden}
                onChange={e => customSetState(e, 'hidden')}/>
            <TextArea
                rows='5'
                label='Holder introduction'
                name='holder_intro'
                error={searchBundleForError(['holder_intro'])}
                value={holder_intro}
                onChange={e => customSetState(e, 'holder_intro')}/>
            <TextInput
                label='Holder template'
                type="text"
                name='holder_template'
                error={searchBundleForError(['holder_template'])}
                value={holder_template}
                onChange={e => customSetState(e, 'holder_template')}/>

            <div id='media'>
                <Heading size='3'>Media (currently {media.length}):</Heading>
                {searchBundleForError(['media']) &&
                    <p className="error">{searchBundleForError(['media'])}</p>
                }
                {media.map((media, index) => {
                    return <div key={media.url} className='media'>
                        <Button
                            btnStyle='danger'
                            text={`Remove media`}
                            onClick={e => handleRemoveMedia(index, e)}/>
                        <Media
                        alt={media.title}
                        key={index}
                        src={`https://assets.worldofwows.com/media/${media.url}`}/>
                        <TextInput
                            label='Title'
                            type="text"
                            name='title'
                            error={searchBundleForError(['media', index, 'title'])}
                            value={media.title}
                            onChange={e => mediaSetState(index, e)}/>
                        <TextInput
                            label='Caption'
                            type="text"
                            name='caption'
                            error={searchBundleForError(['media', index, 'caption'])}
                            value={media.caption}
                            onChange={e => mediaSetState(index, e)}/>
                    </div>
                })}
            </div>

            { displayAddMedia &&
                <MediaForm
                    handleAddNewMediaToState={handleAddNewMediaToState}
                    remapMedia={remapMedia}/>
            }
            { !displayAddMedia &&
                <Button
                    text='Add media'
                    onClick={handleDisplayNewMediaForm}/>
            }

            <div id='holders'>
                <Heading size='3'>Holders (currently {holders.length}):</Heading>
                {searchBundleForError(['holders']) &&
                    <p className="error">{searchBundleForError(['holders'])}</p>
                }
                {holders.map((holder, index) => {
                    return <div className='holder' key={holder.slug}>
                        <Button
                            btnStyle='danger'
                            text={`Remove '${holder.name}'`}
                            onClick={e => handleRemoveHolder(index, e)}/>
                        <p>Reminder: editing the main holder changes the holder details on all related WOWs (this doesn't apply to info data).</p>
                        <div className='main'>
                            <TextInput
                                label='Name'
                                type="text"
                                name='name'
                                error={searchBundleForError(['holders', index, 'name'])}
                                value={holder.name}
                                onChange={e => holdersSetState(index, null, e)}/>
                            <TextInput
                                label='Slug'
                                type="text"
                                name='slug'
                                error={searchBundleForError(['holders', index, 'slug'])}
                                value={holder.slug}
                                onChange={e => holdersSetState(index, null, e)}/>
                            <TextArea
                                rows='2'
                                label='Description'
                                type="text"
                                name='description'
                                error={searchBundleForError(['holders', index, 'description'])}
                                value={holder.description}
                                onChange={e => holdersSetState(index, null, e)}/>
                            <aside>
                                <p>Info data relates specifically to the holder's WOW achievement, rather than being generic to the holder.</p>
                                <TextInput
                                    label='Info value'
                                    type="text"
                                    name='value'
                                    error={searchBundleForError(['holders', index, 'info', 'value'])}
                                    value={holder.info.value}
                                    onChange={e => holdersSetState(index, 'info', e)}/>
                                <TextArea
                                    rows='2'
                                    label='Info description'
                                    type="text"
                                    name='description'
                                    error={searchBundleForError(['holders', index, 'info', 'description'])}
                                    value={holder.info.description}
                                    onChange={e => holdersSetState(index, 'info', e)}/>
                                <TextInput
                                    label='Info held at date'
                                    type="text"
                                    name='held_at'
                                    placeholder='yyyy-mm-dd hh:mm:ss'
                                    error={searchBundleForError(['holders', index, 'info', 'held_at'])}
                                    value={holder.info.held_at}
                                    onChange={e => holdersSetState(index, 'info', e)}/>
                            </aside>
                        </div>
                    </div>;
                })}
            </div>

            { displayAddHolder &&
                <HoldersForm
                    handleAddNewHolderToState={handleAddNewHolderToState}
                    remapHolder={remapHolder}/>
            }
            { !displayAddHolder &&
                <Button
                    text='Add holder'
                    onClick={handleDisplayNewHolderForm}/>
            }

            <div id='themes'>
                <Heading size='3'>Themes (currently {themes.length}):</Heading>
                {searchBundleForError(['themes']) &&
                    <p className="error">{searchBundleForError(['themes'])}</p>
                }
                {themes.map((theme, index) => {
                    return <div className='theme' key={theme.slug}>
                        <Button
                            btnStyle='danger'
                            text={`Remove '${theme.name}'`}
                            onClick={e => handleRemoveTheme(index, e)}/>
                        <div className='main'>
                            <TextInput
                                label='Name'
                                type="text"
                                name='name'
                                error={searchBundleForError(['themes', index, 'name'])}
                                value={theme.name}
                                onChange={e => themesSetState(index, e)}/>
                            <TextInput
                                label='Slug'
                                type="text"
                                name='slug'
                                error={searchBundleForError(['themes', index, 'slug'])}
                                value={theme.slug}
                                onChange={e => themesSetState(index, e)}/>
                            <TextArea
                                rows='2'
                                label='Description'
                                type="text"
                                name='description'
                                error={searchBundleForError(['themes', index, 'description'])}
                                value={theme.description}
                                onChange={e => themesSetState(index, e)}/>
                        </div>
                    </div>;
                })}
            </div>

            { displayAddTheme &&
                <ThemesForm
                    handleAddNewThemeToState={handleAddNewThemeToState}
                    remapTheme={remapTheme}/>
            }
            { !displayAddTheme &&
                <Button
                    text='Add theme'
                    onClick={handleDisplayNewThemeForm}/>
            }

            <div id='submit'>
                <SubmitButton
                    btnStyle='orange'
                    text='Submit form'/>
            </div>
        </FormWrapper>
    )
}

export default WowForm