import React from 'react'
import { withRouter } from 'react-router-dom';

import Button from '../../components/FormComponents/Button'

import { Wrapper } from './style'

const BreadcrumbTopper = (props) => {

    const handleGoBack = () => {
        props.history.goBack();
    }

    return (
        <Wrapper>
            <Button
                btnStyle='text'
                icon='back'
                iconPosition='left'
                size='small'
                text='Back'
                onClick={handleGoBack} />
            {props.rhs}
        </Wrapper>
    )

}

export default withRouter(BreadcrumbTopper)