import styled from 'styled-components'

export const HomeWrapper = styled.div`
    display: flex;
    flex-direction: column;

    p {
        margin: 0.4rem 0;
    }

`;

export const HomeLower = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    section {
        width: 50%;

        a {
            margin-top: 0.5rem;
        }

        > div {
            text-align: right;
        }
    }

    section:nth-child(1) {
        margin-right: 0.5rem;
    }

    section:nth-child(2) {
        margin-left: 0.5rem;

            > article:nth-child(2) {
                h4 {
                    margin-top: 0;
                }
            }
    }
`;

export const WowList = styled.ul`
    list-style: none;
    padding: 0;
`;

export const WowListItem = styled.li`

    margin: 1rem 0;

    a {
        color: #000;
    }

    a:hover {
        border-bottom: 2px solid #000;
    }

`;