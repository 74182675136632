import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { useDebounce } from 'use-lodash-debounce'

import BreadcrumbTopper from '../../components/BreadcrumbTopper'
import Button from '../../components/FormComponents/Button'
import Error from '../../components/Error'
import Heading from '../../components/Heading'
import Loading from '../../components/Loading'
import FilterForm from '../../components/FilterForm'

import { QUERY_SEARCH } from './graphql';

import { SearchWrapper, WowList, Wow } from './style'

const Search = () => {

    //Turn props.location.search into params
    const params = {}
    document.location.search.substr(1).split('&').forEach(pair => {
        const [key, value] = pair.split('=')
        params[key] = value.replace('+', ' ')
    })

    const [ orderBy, setOrderBy ] = useState('title');
    const [ direction, setDirection ] = useState('ASC');
    const [ showFilters, setShowFilters ] = useState(false);
    const [ q, setQ ] = useState(params.q);
    const debouncedQ = useDebounce(q, 1000);

    const prevScrollY = useRef(0);

    let showMoreResultsButton = true;

    const handleScroll = () => {
        const currentScrollY = window.scrollY
        if (prevScrollY.current < currentScrollY) {
            //If screen pos is at the bottom, trigger fetchMore
            if (currentScrollY + window.innerHeight >= document.body.clientHeight) {
                console.log(loading, data);
                if (!loading && data) {
                    if (data.search.cursor) {
                        onLoadMore();
                    }
                }
            }

        }
        prevScrollY.current = currentScrollY;
    }


    const handleOptionUpdate = (event) => {
        switch (event.target.name) {
            case 'direction':
                setDirection(event.target.value)
                break
            case 'orderBy':
                setOrderBy(event.target.value)
                break
            case 'q':
                setQ(event.target.value)
                
                break
            default:
                break
        }
    }

    const handleToggleFilter = () => {
        setShowFilters(!showFilters);
    }

    useEffect(() => {
        console.log('useEffect');
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    })


    const { loading, error, data, fetchMore } = useQuery(QUERY_SEARCH, {
        notifyOnNetworkStatusChange: true,
        variables: {
            q: debouncedQ,
            orderBy: orderBy,
            direction: direction
        },
    });

    let searchContent;

    const onLoadMore = () => {
        return fetchMore({
            query: QUERY_SEARCH,
            notifyOnNetworkStatusChange: true,
            variables: {
                cursor: data.search.cursor,
                q: q,
                orderBy: orderBy,
                direction: direction
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {

                console.log('results', previousResult, fetchMoreResult);

                const newResults = fetchMoreResult.search.wows;
                const newCursor = fetchMoreResult.search.cursor;

                let displayWows;
                //If previous orderBy or direction are different, start list again
                if (previousResult.search.orderBy === fetchMoreResult.search.orderBy &&
                    previousResult.search.direction === fetchMoreResult.search.direction) {
                        console.log('Add new to existing');
                        displayWows = [...previousResult.search.wows, ...newResults]
                    } else {
                        console.log('Display only new');
                        displayWows = newResults
                    }

                return {
                    search: {
                        cursor: newCursor,
                        wows: displayWows,
                        __typename: previousResult.search.__typename,
                    },
                }
            }
        })
    }

    if (loading || q !== debouncedQ) {
        searchContent = <Loading />
    }
    if (error) {
        searchContent= <Error />
    }
    if (data) {
        //Hide the 'load more' button if there's no more data
        if (!data.search.wows || data.search.wows.length === data.search.result_count) {
            showMoreResultsButton = false;
        }

        if (data.search.wows && data.search.wows.length && q === debouncedQ) { //If the search

            const wowContent = data.search.wows.map(wow => {
                return <Wow key={wow.id}>
                    <Link to={`/wows/${wow.slug}`}>
                        {wow.title}
                    </Link>
                </Wow>
            })

        searchContent = <WowList>{wowContent}</WowList>

        } else {
            if (q === debouncedQ) {
                if (q.length < 3) {
                    searchContent = 'Searches must be 3 or more characters'
                } else {
                    searchContent = 'No search results found'
                }
            } else {
                searchContent = <Loading />
            }
        }

    }

    return (
        <SearchWrapper>
            <BreadcrumbTopper
                rhs={
                    <Button
                        size='small'
                        btnStyle='text'
                        text={`${showFilters ? 'Hide' : 'Show'} filter`}
                        onClick={handleToggleFilter} />
                }/>
            <FilterForm
                showForm={showFilters}
                fields={[
                    {
                        name: 'orderBy',
                        type: 'select',
                        value: orderBy,
                        label: 'Order by',
                        options: [
                            {
                                value: 'title',
                                display: 'WOW title'
                            }
                        ]
                    },
                    { 
                        name: 'direction',
                        type: 'select',
                        value: direction,
                        options: [
                            {
                                value: 'ASC',
                                display: 'A-Z'
                            },
                            {
                                value: 'DESC',
                                display: 'Z-A'
                            }
                        ]
                    }
                ]}
                searchField={
                    {
                        name: 'q',
                        value: q
                    }
                }
                onOptionUpdate={event => handleOptionUpdate(event)} />
            <Heading size='1' aside={ data ? `(${data.search.result_count} results in total)` : null}>Search results for &quot;{q}&quot;</Heading>
            {searchContent}
            { !loading && showMoreResultsButton &&
                <Button
                    text='Load more results'
                    onClick={onLoadMore} />
            }
        </SearchWrapper>
    )

}

export default Search