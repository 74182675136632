import styled from 'styled-components'

export const NewsWrapper = styled.div`

    > button {
        margin: 0 auto;
    }

`;

export const NewsItemWrapper = styled.article`
    overflow: hidden;
    text-align: left;
`;