import { gql } from "apollo-boost";

export const QUERY_WOW_BY_SLUG = gql`
query WowQuery($slug: String!){
  wowBySlug(slug: $slug){
      title
      content
      holder_intro
      holder_template
      holders {
        name
        description
          info {
            value
            description
            held_at
        }
      }
      themes {
        slug
        name
      }
      media {
        url
        file_type
        title
        caption
      }
  }
}
`;