import React from 'react'

import Icon from '../../../components/Icon'

import { Button } from './style';

const SubmitButton = (props) => {

    return (
    <Button {...props}>
        {props.children}
        { props.icon &&
            <Icon name={props.icon} width='1rem' />
        }
        { props.text &&
            <span>{props.text}</span>
        }
    </Button>
    )

}

export default SubmitButton