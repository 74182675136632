import React from 'react'

import { InputWrapper, Label } from './style'

const MediaInput = (props) => {

    return (
        <Label {...props}>
            { props.label &&
                <span>{props.label}</span>
            }
            <InputWrapper
                type='file'
                ref={props.fileRef}
                {...props} />
        </Label>
    )

}

export default MediaInput