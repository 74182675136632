import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'

import Button from '../../../../components/FormComponents/Button'
import Error from '../../../../components/Error'
import Loading from '../../../../components/Loading'
import Option from '../../../../components/FormComponents/Select/option'
import TextArea from '../../../../components/FormComponents/TextArea'
import TextInput from '../../../../components/FormComponents/TextInput'
import Select from '../../../../components/FormComponents/Select'

import { QUERY_SEARCH_THEMES, QUERY_THEME_BY_SLUG } from './graphql'

import { AddTheme } from './style'

const ThemesForm = (props) => {

    const themeObject = {
        slug: '',
        name: '',
        description: ''
    }

    const [ q, setQ ] = useState('')
    const [ theme, setTheme ] = useState(themeObject)
    const [ themePicked, setThemePicked ] = useState('default-option')
    const [ getThemes, { loading, error, data } ] = useLazyQuery(QUERY_SEARCH_THEMES)
    const [ getThemeBySlug, { loading: themeLoading, error: themeError, data: themeData } ] = useLazyQuery(QUERY_THEME_BY_SLUG)

    /*
     * lastFormAction used to keep track of current form status dut to multiple queries / data / states
     * 0 = Nothing
     * 1 = Searched for themes
     * 2 = Picked theme
     * 3 = Edited text field 
     */
    const [ lastFormAction, setLastFormAction ] = useState(0);

    let themeSelect

    if (loading) {
        themeSelect = <Loading />
    }
    if (error) {
        themeSelect = <Error />
    }
    if (data) {
        //console.log('themes query run');

        let options = data.themes.themes.map(theme => {
            return <Option
                key={theme.slug}
                value={theme.slug}
                display={theme.name}/>
        })

        options.unshift(<Option key='default-option' value='default-option' display='Choose a theme' disabled={true}/>)

        themeSelect = <Select
            label='Find an existing theme'
            options={options}
            value={themePicked}
            onChange={e => handlePickTheme(e)}/>
    }

    const handleSetQuery = (e) => {
        setQ(e.target.value);
        setLastFormAction(1)

        if (e.target.value.length >= 3) {
            getThemes({ variables: { q: e.target.value } })
        }
    }

    const handlePickTheme = (e) => {
        //console.log('handlePickHolder');
        setThemePicked(e.target.value)
        setLastFormAction(2)
        getThemeBySlug({ variables: { slug: e.target.value } })
    }

    const handleInputChange = (e) => {
        setLastFormAction(3);

        const { name, value } = e.target
        setTheme(prevState => {
            const stateDup = {...prevState} //Duplicate prevState (so we aren't mutating it directly)
            stateDup[name] = value //Update the given property
            if(name === 'name') {
                stateDup['slug'] = value.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\d-]/g, '')
            }
            return stateDup //Set state using the duplicate
        })
    }

    if (themeLoading) {
        themeSelect = <Loading />
    }
    if (themeError) {
        themeSelect = <Error />
    }
    if (themeData) { //Need to make sure this only runs once
        //console.log('Update theme state?', theme.name, ' & ', themeData.themeBySlug.name)
        if (theme.name !== themeData.themeBySlug.name && lastFormAction !== 3) { //Make sure this isn't when the text fields are updated, as it will overwrite
            //console.log('name doesnt match, setting');
            setTheme(props.remapTheme(themeData.themeBySlug))
        }
    }

    //console.log('Theme data is now', theme);

    return (
        <AddTheme>
            <p>Add a theme:</p>
            <TextInput
                label='Search for theme'
                type="text"
                name='theme_search'
                value={q}
                onChange={e => handleSetQuery(e)}/>

                {themeSelect &&
                    themeSelect}

                {/* OnClick, add content to text boxes and make un-editable (changing the slug would cause a new record to be created) */}

            <div id='theme'>
                <TextInput
                    label='Name'
                    type="text"
                    name='name'
                    value={theme.name}
                    onChange={e => handleInputChange(e)}
                    />
                <TextInput
                    label='Slug'
                    type="text"
                    name='slug'
                    value={theme.slug}
                    onChange={e => handleInputChange(e)}/>
                <TextArea
                    rows='2'
                    label='Description'
                    type="text"
                    name='description'
                    value={theme.description}
                    onChange={e => handleInputChange(e)}/>
                <Button
                    text='Add this theme to WOW'
                    onClick={e => props.handleAddNewThemeToState(e, theme)}/>
            </div>

        </AddTheme>
    )
}

export default ThemesForm