import React from 'react'

import BreadcrumbTopper from '../../components/BreadcrumbTopper'
import Heading from '../../components/Heading'

import { FAQWrapper, FAQList, Question, Answer } from './style'

const FAQs = () => {

    const faqContent = [
        {
            id: 'what-is-it',
            question: 'What is the World of WOWs?',
            __html: `<p>Let me tell you a short story about <strong>WOW</strong>s. Sit back, relax... but don't relax so much that your eyes close. Because you need to be able to read. Unless you are blind, and are using some manner of vocal synthesis software - in which case, please continue to be blind.</p>
            <p>For several years now, I have been travelling the world, seaching for the most mind-blowing, most unbelievable facts - and recording them!</p>
            <p>Originally, this was done via a free blogspot blog... until it was suggested that the WOWs needed a true home! And so I set about creating this website, and I called it <strong>The World of WOWs</strong>... because that was the blog was called.</p>
            <p>The end.</p>`
        },
        {
            id: 'how-is-it',
            question: 'How is the World of WOWs different from other record collections?',
            __html: `<p>There are many fact compilations out there. All are full of mundane, intensely boring facts - facts that no-one ever wanted, or asked to know about - for they are compiled by faceless android drones, with stopwatches surgically embedded within their palms - these collections are a vision of hell to a <strong>WOW</strong> seeker.</p>
            <p><strong>The World of WOWs</strong> is different in every way! Here is a comparison involving sponges:</p>
            <p>Other collections are akin to the sponges that you use to clean your toilet. Disgusting - for a start, you're not supposed to use a sponge to clean your toilet - that's hyginic insanity!</p>
            <p><strong>The World of WOWs</strong> is also a sponge - but it is an exciting cartoon sponge-based character, as you may have seen on children's television.</p>
            <p>What would you rather have? A fist full of reeking human feces, or a fun animation? It is your decision.</p>
            <p>Make your decision!</p>`
        },
        {
            id: 'are-these',
            question: 'Are these world records? Are they real?',
            __html: `<p>No, these are not world records. If you are interested in world records, there are plenty of alternate places you can go.<br>
            These are world WOWs, a far more interesting beast!</p>
            <p>As for whether or not they are <em>real</em>, I can gaurantee you this - that every WOW you discover on <strong>The World of WOWs</strong> is true!<br>
            There's no denying it. Don't try to deny it, because that would be wrong.</p>
            <p>Just like punching a dolphin.</p>`
        },
        // {
        //     question: 'How can I get a WOW onto the site?',
        //     answer: `<p>It's a very simple process. If it's a new WOW, just visit the <a href="http://submit.worldofwows.com" title="Submit a WOW">Submit</a> section of the site, fill in and submit the form. That's it!<br>
        //     Only minimal details are required - and no verification, proof or evidence is required.</p>
        //     <p>If you believe you have beaten an existing WOW, use the '<em>Challenge this WOW</em>' link at the bottom of the WOW's page, and fill in the form as usual.</p>
        //     <p>Most WOWs will move from the queue onto the main site within a week, and every submittor will receive a digital copy of their personalised World of WOWs certificate.</p>`
        // },
        // {
        //     question: 'How can get in touch with you?',
        //     answer: `Twitter @WorldOfWows`
        // },
    ]

    const faqItems = faqContent.map((faq, index) => {
        return <div key={index}>
            <Question><Heading size='2'>{faq.question}</Heading></Question>
            <Answer dangerouslySetInnerHTML={faq} />
        </div>
    })

    return (
        <FAQWrapper>
            <BreadcrumbTopper/>
            <Heading
                size='1'>
                Frequently Asked Questions
            </Heading>
            <div>
                <p>Just <em>what is</em> the World of WOWs? That's a question I've often asked myself.<br />
                Of course, I already know the answer. Because I am me.<br />
                But what about people who aren't me? What about <em>you</em>?</p>
                <p>For you, there is this page!</p>
            </div>
            <FAQList>
                {faqItems}
            </FAQList>
        </FAQWrapper>
    )
}

export default FAQs