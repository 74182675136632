import React from 'react'

import { LogoWrapper } from './style'

const Logo = () => {

    return (
        <LogoWrapper>
            <span>The World of WOWs</span>
            <span>W</span>
        </LogoWrapper>
    )

}

export default Logo