import React from 'react'
import  { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import Error from '../../components/Error'
import Loading from '../../components/Loading'

import { QUERY_REDIRECT } from './graphql';

const RedirectAttempt = (props) => {

    let redirectContent

    const { loading, error, data } = useQuery(QUERY_REDIRECT, {
        variables: {
            slug: props.match.params.slug
        },
    });

    if (loading) {
        redirectContent = <Loading />
    }
    if (error) {
        redirectContent= <Error />
    }
    if (data) {

        if (data.redirect.code !== 404 && (data.redirect.data === 'wow' || data.redirect.data === 'theme')) {
            //A redirect was found
            if (data.redirect.data === 'wow') {
                redirectContent = <Redirect to={`/wows/${data.redirect.url}`} />
            } else if (data.redirect.data === 'theme') {
                redirectContent = <Redirect to={`/categories/${data.redirect.url}`} />
            }

        } else {
            //Else send to the default error page
            redirectContent = <Redirect to={`/error`} />
        }
        
    
    }

    return redirectContent;

}

export default RedirectAttempt