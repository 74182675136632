import styled from 'styled-components'

export const FAQWrapper = styled.div`
    text-align: left;
`;

export const FAQList = styled.dl`
`;

export const Question = styled.dt`
`;

export const Answer = styled.dd`
    margin: 0;
`;