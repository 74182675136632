import { gql } from "apollo-boost";

export const QUERY_ALL_NEWS = gql`
query NewsQuery{
  news(limit:100){
    items {
        id
        title
        slug
    }
  }
}
`;

export const QUERY_NEWS_BY_SLUG = gql`
query NewsQuery($slug: String!){
  newsBySlug(slug: $slug){
      title
      slug
      content
  }
}
`;

export const MUTATION_UPDATE_NEWS = gql`
mutation NewsMutation(
        $slug: String!,
        $title: String!,
        $content: String!,
    ) {
    upsertNews(
        slug: $slug
        title: $title,
        content: $content,
    ) {
        slug
    }
}
`;