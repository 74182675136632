import React, { useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import BreadcrumbTopper from '../../components/BreadcrumbTopper'
import Error from '../../components/Error'
import Heading from '../../components/Heading'
import Loading from '../../components/Loading'
import MediaGallery from '../../components/MediaGallery'
import Nav from '../../components/Nav'
import UserContext from '../../contexts/UserContext'

import { QUERY_WOW_BY_SLUG } from './graphql'

import { WowWrapper, LowerWow } from './style'

const Wow = (props) => {

    const { loading, error, data } = useQuery(QUERY_WOW_BY_SLUG, {
        variables: {
            slug: props.match.params.slug
        },
    });

    const { currentUser } = useContext(UserContext);

    const monthString = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]

    let wowContent;

    if (loading) {
        wowContent = <Loading />
    }
    if (error) {
        wowContent= <Error />
    }
    if (data) {

        if (data.wowBySlug) { //If the wow exists

            //Holder information needs to be pieced together and put into a dangerouslyUnsafe html component

            //Holders first need to be ordered by holder.info.held_at, since I can't figure out how to do that in GQL
            //const holdersOrdered = data.wowBySlug.holders.sort((a, b) => new Date(a.info.held_at) + new Date(b.info.held_at))
            const holdersOrdered = data.wowBySlug.holders.slice().sort((a, b) => {
                const date1 = new Date(a.info.held_at).getTime()
                const date2 = new Date(b.info.held_at).getTime()
                if (date1 > date2) {
                    return -1
                } else if(date1 < date2) {
                    return  1
                } else {
                    return  0
                }
            })

            let holderIntro, holderMiddle, holderEnd, holderContent, previousHolders, previousHolderItems

            holderIntro = data.wowBySlug.holder_intro;

            //Is there a template we need to fill out?
            if (data.wowBySlug.holder_template) {
                //Look for <holder> and <value> markers, replace with the holder and value from the most recent holder
                holderMiddle = data.wowBySlug.holder_template.replace('<holder>', holdersOrdered[0].name).replace('<value>', holdersOrdered[0].info.value)
                
            } else {
                //If the holder has a description, add a footnote icon and add description to the bottom of the page
                holderMiddle = holdersOrdered[0].name;
            }

            holderEnd = holdersOrdered[0].info.description

            //Make sure holderMiddle has a space at the start and a full stop at the end
            holderMiddle = ' ' + holderMiddle.trim() + (holderMiddle[holderMiddle.length - 1] !== '.' ? '.' : '')
            //If holderEnd isn't null and doesn't have a <p> at the start, surround it with <p> tags
            if (holderEnd && holderEnd.trim().slice(0,3) !== '<p>') {
                holderEnd = `<p>${holderEnd}</p>`
            }
/*
            console.log('intro', holderIntro);
            console.log('middle', holderMiddle);
            console.log('end', holderEnd);
*/
            holderContent = { __html: '<p>' + holderIntro + holderMiddle + '</p>' + (holderEnd ? holderEnd : '') }

            //If there's more than one holder, create the previous holders section
            if (holdersOrdered.length > 1) {
                holdersOrdered.splice(0, 1);
                //Only map the second holder onwards, since the first is displayed as the current holder
                previousHolderItems = holdersOrdered.map((holder, index) => {
                    const date = new Date(holder.info.held_at);
                    //Remove any <p> tags surrounding the description
                    const description = holder.info.description.replace('<p>','').replace('</p>','')
                    return <li key={index}><strong>{monthString[date.getMonth()] + ' ' + date.getFullYear()}:</strong><span>{holder.name}. {description}</span></li>
                })

                previousHolders = <section>
                    <Heading size='4'>Previous holders of this WOW include:</Heading>
                    <ul>
                        {previousHolderItems}
                    </ul>
                </section>

            }

            const tagItems = data.wowBySlug.themes.map(theme => {
                return {
                    linkStyle: 'button',
                    title: theme.name,
                    url: `/categories/${theme.slug}`
                }
            })

            //React handling of HTML in content:
            const mainWowHTMLContent = { __html: data.wowBySlug.content }

            //Media
            let media
            if (data.wowBySlug.media.length) {
                media = <MediaGallery
                    items={data.wowBySlug.media}/>
            }

            wowContent = <>
            <Heading size='1'>{data.wowBySlug.title}</Heading>
            { media && media }
            <div dangerouslySetInnerHTML={mainWowHTMLContent} />
            <div dangerouslySetInnerHTML={holderContent} />
            {previousHolders}
            { tagItems &&
                <LowerWow>
                    <Heading size='4'>Related categories:</Heading>
                    <Nav
                        navItems = {tagItems} />
                </LowerWow>
            }
            </>

        } else {

            return <Redirect to={`/error`} />

        }
    }

    let breadcrumbRhs;

    if (currentUser && currentUser.is_admin) {
        breadcrumbRhs = <Link to={`/admin/wows/${props.match.params.slug}`}>Edit this WOW</Link>
    }

    return (
        <WowWrapper>
            <BreadcrumbTopper rhs={breadcrumbRhs}/>
            {wowContent}
        </WowWrapper>
    )
}

export default Wow