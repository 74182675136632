import styled from 'styled-components'

export const FormWrapper = styled.form`

    border-top: 1px solid #000;
    padding-top: 1rem;

    label {
        span {
            display: block;
        }

        input, textarea {
            width: 60%;
        }

        display: block;
    }

    #holders, #themes, #media {

        margin-top: 1rem;

        .holder, .theme, .media {

            border: 1px solid #000;
            padding: 1rem;

            > p {
                margin: 0 0 1rem 0;
            }

            aside {
                p {
                    margin: 0 0 1rem 0;
                }
                background: #EEE;
                padding: 1rem;
            }
            
            Button {
                float: right
            }

        }
    }

    #media {
        img {
            max-height:200px;
        }
    }

    .error {
        color: #F00;
        font-size: 0.8rem;
        margin: 0;
    }

    #submit {
        margin: 1rem 0;
        text-align: center;
    }
`;