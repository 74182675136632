import React, { useContext } from 'react'
import  { Redirect } from 'react-router-dom'
import { Route } from 'react-router-dom'

import UserContext from '../../contexts/UserContext'

const ProtectedRoute = ({ component: Component, adminOnly, ...rest }) => {

    const { currentUser } = useContext(UserContext);

    if (currentUser) {
        //console.log('Current user now exists', currentUser);
        return (
            <Route {...rest} render={(props) => (
                currentUser.email === 'guest' || (adminOnly && !currentUser.is_admin)
                ? <Redirect to={{
                    pathname: (adminOnly ? '/' : '/login'),
                    state: { from: props.location }
                }} />
                : <Component {...props} />
            )} />
        )
    }

    return <p>Loading...</p>

}

export default ProtectedRoute