import React, { useState, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import axios from 'axios'

import Button from '../../../../components/FormComponents/Button'
import Error from '../../../../components/Error'
import Loading from '../../../../components/Loading'
import MediaInput from '../../../../components/FormComponents/MediaInput'
//import Option from '../../../../components/FormComponents/Select/option'
import TextInput from '../../../../components/FormComponents/TextInput'
//import Select from '../../../../components/FormComponents/Select'

// import { QUERY_SEARCH_HOLDERS, QUERY_HOLDER_BY_SLUG } from './graphql'
import { MUTATION_UPLOAD_MEDIA, MUTATION_UPDATE_MEDIA } from './graphql'

// import { AddHolder } from './style'

// This differs from the holder and theme form:
// As well as a query for searching for existing images (restrict to images uploaded by the current user)
// Contains mutation that runs to upload the media file itself, if successfull, returns the generated media record back to the

const MediaForm = (props) => {

    const [
        uploadMedia,
        { loading: mLoading, error: mError, data: mData }
    ] = useMutation(MUTATION_UPLOAD_MEDIA, {
        onError(error) {
            //Not declaring this seems to cause an unhandled error exception when a mutation error (ie validation) is thrown
            //Maybe a bug in Apollo?
        }
    });

    const [
        updateMedia,
        { called: muCalled, loading: muLoading, error: muError, data: muData }
    ] = useMutation(MUTATION_UPDATE_MEDIA, {
        onError(error) {}
    });

    const fileRef = useRef(null);
    const [filesPicked, setFilesPicked] = useState();
    const [fileInformation, setFileInformation] = useState([]);

    /*
     * After picking, display form elements for each element
     */
    const handleFilesPicked = event => {
        event.preventDefault();
        event.stopPropagation();
        console.log('Files have been picked: ', fileRef.current.files);
        setFilesPicked(fileRef.current.files);
        //At the same time as setting picked, also create the same number of blank objects in the info state
        let emptyFileInfo = [];
        for (const file in fileRef.current.files) { //FileRef is an object of objects
            if (Number.isInteger(Number(file))) { //FileRef will also contain objects that aren't files, ignore them
                emptyFileInfo.push({
                    title: '',
                    caption: ''
                })
            }
        }
        setFileInformation(emptyFileInfo);
    }

    const mediaSetState = (index, e) => {
        const { name, value } = e.target
        setFileInformation(prevState => {
            const stateDup = [...prevState] //Duplicate prevState (so we aren't mutating it directly)
            stateDup[index][name] = value //Update the given property
            return stateDup //Set state using the duplicate
        })
    }

    /*
     * Submit form -> mutation
     */
    const handleSubmit = event => {
        event.preventDefault();
        console.log('File ref contains: ', filesPicked);

        let files = [];

        for (const file in filesPicked) { //FileRef is an object of objects
            if (Number.isInteger(Number(file))) { //FileRef will also contain objects that aren't files, ignore them
                console.log('Now looking at ', file);
                files.push(
                    {
                        name: filesPicked[file].name,
                        size: filesPicked[file].size,
                        title: fileInformation[file].title, //Add the title and caption from the fileInformation state
                        caption: fileInformation[file].caption
                    }
                )
            }
        };

        console.log('Submitting with ', files);

        uploadMedia({ variables: {
            files
        } });
    }

    /*
     * Media picker should really include search option so that existing images can be added
     * Will look at that once WOWs are working on the main WOW pages
     */
    const mediaPicker = <div>
        <MediaInput
            label='Pick file(s)'
            name='mediaInput'
            multiple={true}
            fileRef={fileRef}
            onChange={e => handleFilesPicked(e)} />
        
    </div>

    let mediaInputForm = mediaPicker //By default, mediaPicker is displayed

    //let generalErrorMsg;
    let errorBundle = [];

    if (mLoading && !muCalled) {
        mediaInputForm = <Loading />
    }
    if (mError && !muCalled) {
        console.log(mError)
        mediaInputForm = <Error />

        //generalErrorMsg = mError.graphQLErrors[0].message
        //Extract the erroring input names, and their error msg
        for (const error in mError.graphQLErrors[0].extensions) {
            //console.log(mError.graphQLErrors[0].extensions[error]);
            errorBundle.push({
                name: mError.graphQLErrors[0].extensions[error].path,
                msg: mError.graphQLErrors[0].extensions[error].message
            })
        }

    }

    const searchBundleForError = (fieldArray) => {
        let returnError = null;
        //Try to match the given fieldArray with the path of each element in the errorBundle
        errorBundle.forEach((errorElement) => {
            if (errorElement.name && errorElement.name.length === fieldArray.length && errorElement.name.every((element, index) => { return element === fieldArray[index] })) {
                returnError = errorElement.msg;
            }
        });
        return returnError;
    }

    if (filesPicked) {

        //Loop through filesPicked, create form elements with title and caption for each
        //The titles and captions need to be added to the filesPicked state so they can be sent in the first mutation and validated
        let formElements = [];

        for (const filePicked in filesPicked) { //FileRef is an object of objects
            console.log('Filepicked = ', filePicked)
            if (Number.isInteger(Number(filePicked))) { //FileRef will also contain objects that aren't files, ignore them
                formElements.push(
                    <div key={Number(filePicked)}>
                        <div className='preview'>{filesPicked[filePicked].name}</div>
                        <TextInput
                            label='Title'
                            type="text"
                            name='title'
                            error={searchBundleForError(['files', Number(filePicked), 'title'])} //Note, it's files instead of media, because files is the name of the containign object sent in the signedURLrequest mutation
                            value={fileInformation[Number(filePicked)].title}
                            onChange={e => mediaSetState(Number(filePicked), e)}/>
                        <TextInput
                            label='Caption'
                            type="text"
                            name='caption'
                            error={searchBundleForError(['files', Number(filePicked), 'caption'])}
                            value={fileInformation[Number(filePicked)].caption}
                            onChange={e => mediaSetState(Number(filePicked), e)}/>
                    </div>
                )
            }
        };

        mediaInputForm = <div>
            <div id='formElements'>
                {formElements}
            </div>
            <Button
                text={`Upload ${filesPicked.length} ${filesPicked.length > 1 ? 'files' : 'file'}`}
                onClick={e => handleSubmit(e)}/>
        </div>
    }

    if (mData && !muCalled) {
        console.log('Data from S3 = ', mData.mediaSignedUrlRequest)
        //mData will contain a signed URL from S3. Now use Axios to upload the image to the given url. Once that's successfull, run another mutation to create the media record
        mediaInputForm = <Loading />

        let uploadResponses = [];
        let promises = [];

        console.log('Loop through filesPicked');
        for (const fileRef in filesPicked) { //FileRef is an object of objects
            if (Number.isInteger(Number(fileRef))) { //FileRef will also contain objects that aren't files, ignore them
                const file = filesPicked[fileRef]
                console.log('Current file picked is', file);

                //Find the matching name in the mData.mediaSignedUrlRequest and use the url
                const matchedRequest = mData.mediaSignedUrlRequest.find(signedUrl => {
                    return signedUrl.name === file.name
                })

                //Build options
                const options = {
                    headers: { 'Content-Type': file.type },
                    crossdomain: true //Not sure this is needed?
                }

                promises.push(
                    axios.put(matchedRequest.url, file, options)
                    .then(response => {
                        console.log('Uploaded a file, YES!');
                        uploadResponses.push(response);
                    })
                    .catch(error => {
                        uploadResponses.push(error);
                        console.log('Error uploading', error);
                    })
                )
            }
        }

        //File(s) have been uploaded to S3, now put together upsert mutation to create the DB records
        Promise.all(promises).then(() => {
            let media = [];
            uploadResponses.forEach((uploadResponse, index) => { //Loop through uploadResponses
                console.log('Response: ', uploadResponse);
                //If it's not an error response (how to test this??)
                //url and filetype come from the upload response (do some regex to extract url)
                const regex = /\/media\/(.*)\?/
                const fileUrl = uploadResponse.config.url.match(regex)[1];

                media.push({
                    url: fileUrl,
                    file_type: uploadResponse.config.data.type,
                    title: fileInformation[index].title,
                    caption: fileInformation[index].caption
                })
            })
            //Trigger the upsert mutation to create the media records
            updateMedia({ variables: {
                media
            } });

        });
    }

    if (muLoading) {
        mediaInputForm = <Loading />
    }

    if (muError) { //There shouldn't really be any errors, since the title and caption were validated earlier
        mediaInputForm = <Error />
    }

    /*
     * Finally, update the WOW details page state to include the media record
     */
    if (muData) {

        let cleanedMedia = [];

        //First re-map media to remove any extra properties from the query
        muData.upsertMedia.forEach(media => {
            cleanedMedia.push(props.remapMedia(media))
        })
        
        console.log('Media upserted to DB', muData);
        mediaInputForm = <p>Finished!</p>

        props.handleAddNewMediaToState(cleanedMedia);
    }

    return mediaInputForm

}

export default MediaForm