import React from 'react';
import { NavLink } from 'react-router-dom';

import { ListWrapper } from './style'

const ListItem = (props) => {

    return (
        <ListWrapper linkStyle={props.linkStyle}>
            <NavLink
                to={props.url}>
                {props.title}
            </NavLink>
        </ListWrapper>
    )
}

export default ListItem;