import React from 'react'

import Icon from '../../Icon'

import { ButtonWrapper } from './style'

const Button = props => {

    let iconLeft, iconRight

    if (props.icon) {
        if (!props.iconPosition || props.iconPosition === 'right') {
            iconRight = <Icon name={props.icon} width='1rem' />
        } else {
            iconLeft = <Icon name={props.icon} width='1rem' />
        }
    }

    return (
    <ButtonWrapper onClick={props.onClick} btnStyle={props.btnStyle} size={props.size}>
        {iconLeft}
        { props.text &&
            <span>{props.text}</span>
        }
        {iconRight}
    </ButtonWrapper>
    )

}

export default Button

