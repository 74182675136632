import styled from 'styled-components'
import { device } from '../../helpers/device'

export const LayoutWrapper = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;

    a {
        text-decoration: none;
    }

    font-family: 'Open Sans', sans-serif;

    @media ${device.tablet} {
        max-width: 1024px;
    }

    > main {

        margin: 1rem 0;

    }

`;