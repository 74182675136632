import styled from 'styled-components'
import { device } from '../../helpers/device'

export const MediaWrapper = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    #thumbs{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;

        > div {
            background-size: cover;
            background-position: center;
            border: 2px solid #72BA1C;
            border-radius: 10px;
            cursor: pointer;
            margin: 0.2rem 0.1rem;
            width:  100px;
            height: 80px;
        }
    }

    @media ${device.tablet} {

        flex-direction: row;
        max-width: 80%;

        #thumbs {
            flex-direction: column;
            margin: 0 0.5rem;
        }
    }
`;