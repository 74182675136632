import { gql } from "apollo-boost";

export const QUERY_REDIRECT = gql`
query Redirect($slug: String!){
    redirect(slug: $slug) {
        code
        url
        data
    }
}
`;