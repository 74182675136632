import styled from 'styled-components'
import { device } from '../../../helpers/device'

export const HeaderWrapper = styled.header`

    background: #EEE;
    padding: 0.3rem;
    border-radius: 5px;

    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h1 {
            margin: 0;
        }

        aside {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 80%;

            > aside {
                color: #666;
                font-weight: bold;
                font-size: 0.8rem;

                > a {
                    color: #65A21D;
                }

                > a:hover {
                    color: #000;
                }
            }

            > div {
                display: none;
            }

        }
    }

    .bottom {

        border-top: 2px solid #000;
        margin-top: 0.3rem;
        padding-top: 0.3rem;
        display: flex;
        justify-content: space-evenly;
        width: 100%;

        > form {
            > input {
                margin-right: 0;
            }
            > button {
                display: none;
            }
        }

    }

    > div {
        display: flex;
    }

    .header_search {
        input {
            margin-right: 0.4rem;
        }
    }

    @media ${device.tablet} {

        padding: 1rem 0;

        .top {
            justify-content: space-evenly;

            > div {
                margin: 0;
                min-width: 400px;
                max-width: 45%;
    
                > aside {
                    margin: -0.3rem 0 0.3rem 0;
                    font-size: 1rem;
                }

                > div {
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
    
            }
    
            > button {
                display: none;
            }
        }

        .bottom {
            display: none;
        }

        > div {
            display: none;
        }

    }

`;