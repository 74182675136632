import styled from 'styled-components'

export const InputWrapper = styled.input`
    border: 2px solid #72BA1C;
    border-radius: 100px;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8rem;
    padding: 3px 10px;
    background: #FFFFFF;
`;

export const Label = styled.label`
    > span {
        margin-right: 0.3rem;
    }

    .helperText {
        color: ${props => props.error ? '#F00' : '#000'};
        font-size: 0.8rem;
        margin: 0;
    }

    margin-bottom: 0.5rem;
`;