import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'

import BreadcrumbTopper from '../../../components/BreadcrumbTopper'
import Error from '../../../components/Error'
import Loading from '../../../components/Loading'
import WowForm from './form'

import { QUERY_WOW_BY_SLUG, MUTATION_UPDATE_WOW } from './graphql'

const WowDetail = (props) => {

    const { loading: qLoading, error: qError, data: qData } = useQuery(QUERY_WOW_BY_SLUG, {
        variables: {
            slug: props.match.params.slug
        },
    });

    const [
        updateWow,
        { loading: mLoading, error: mError, data: mData }
    ] = useMutation(MUTATION_UPDATE_WOW, {
        onError(error) {
            //Not declaring this seems to cause an unhandled error exception when a mutation error (ie validation) is thrown
            //Maybe a bug in Apollo?
        }
    });

    let wowDetailForm;

    if (qLoading || mLoading) {
        wowDetailForm = <Loading />
    }
    if (qError) {
        wowDetailForm= <Error />
    }
    if (qData || mError) {

        let generalErrorMsg;
        let errorBundle = [];

        if(mError) {
            generalErrorMsg = mError.graphQLErrors[0].message
            //Extract the erroring input names, and their error msg
            for (const error in mError.graphQLErrors[0].extensions) {
                //console.log(mError.graphQLErrors[0].extensions[error]);
                errorBundle.push({
                    name: mError.graphQLErrors[0].extensions[error].path,
                    msg: mError.graphQLErrors[0].extensions[error].message
                })
            }
        }
        
        wowDetailForm = <WowForm
            //Include any mError errors as fieldErrors
            updateWow={updateWow}
            {...qData.wowBySlug}
            generalErrorMsg={generalErrorMsg}
            errorBundle={errorBundle}
        />
    }
    if (mData) {
        window.location.href = `/admin/wows/${mData.upsertWow.slug}`; //Just reload the page
        //wowDetailForm = <p>Wow has been updated! YES!</p>
    }

    const breadcrumbRhs = <Link to={`/wows/${props.match.params.slug}`}>View this WOW</Link>

    return (
        <>
            <BreadcrumbTopper rhs={breadcrumbRhs}/>
            {wowDetailForm}
        </>
        
    )
}

export default WowDetail