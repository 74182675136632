import React from 'react'
import { useMutation } from '@apollo/react-hooks'

import LoginForm from './form'
import LoginRedirect from './redirect'

import { SetCookie } from "../../helpers/cookie"

import { MUTATION_LOGIN_USER } from './graphql'

const Login = props => {

    //Redirect to /account if already logged in, no point in asking a logged-in user to log in

    const [
        loginUser,
        { loading, error, called, data }
    ] = useMutation(
        MUTATION_LOGIN_USER,
        {
            onCompleted({ loginUser }) {
                //User details entered correctly, set JWT cookie, valid for a month
                SetCookie('jwtAuthToken', loginUser.token, 31);
            },
            onError() {
                //console.log('An error!', ApolloError);
            }
        }
    );

    let fieldErrors = {};

    if (loading || error || !called) {

        if (error) {
            for (let graphQLErrorKey in error.graphQLErrors) {
                //Could be a can't find user error
                if (error.graphQLErrors[graphQLErrorKey].extensions.code) {
                    if (error.graphQLErrors[graphQLErrorKey].extensions.code === 404) {
                        fieldErrors['overallError'] = {
                            graphQLErrorKey: graphQLErrorKey,
                            message: <p>User Not found. Wrong password?</p>
                        }
                    }
                }
                //Could be a validation error
                //If exception(s) exist, loop through those
                if (error.graphQLErrors[graphQLErrorKey].extensions.exception) {
                    for (let invalidArgKey in error.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs) {
                        fieldErrors[error.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs[invalidArgKey].param] = {
                            graphQLErrorKey: graphQLErrorKey,
                            message: error.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs[invalidArgKey].msg
                        }
                    }
                }
            }
            //Other errors?

            console.log('FeildErrors', fieldErrors);

        }

        return <LoginForm
            login={loginUser}
            loading={loading}
            fieldErrors={fieldErrors}
            />;
    }

    //If no errors, login success!
    return <LoginRedirect
        user={data.loginUser.user}
        locationState={props.location.state} />

}

export default Login;