import React from 'react'

import ListItem from '../ListItem'

import { NavWrapper } from './style'

const Nav = (props) => {

    //Map list to get list items

    const listItems = props.navItems.map((navItem, index) => (
        <ListItem
            key={index}
            linkStyle={navItem.linkStyle}
            title={navItem.title}
            url={navItem.url}/>
    ));

    return (
        <NavWrapper>
            <ul>
                {listItems}
            </ul>
        </NavWrapper>
    )
}

export default Nav;