import React from 'react'

import { MediaWrapper } from './style'

const Media = (props) => {

    return <MediaWrapper>
        <img
            {...props}
            alt={props.alt}/>
        { !props.hideCaption && props.caption &&
            <p>{props.caption}</p>
        }
    </MediaWrapper>

}

export default Media