import React, { useState } from 'react'

import SubmitButton from '../../../components/FormComponents/SubmitButton'
import TextArea from '../../../components/FormComponents/TextArea'
import TextInput from '../../../components/FormComponents/TextInput'

import { FormWrapper } from './style'

const NewsForm = (props) => {

    //console.log(props)

    const handleSubmit = event => {
        event.preventDefault();

        props.updateNews({ variables: {
            slug,
            title,
            content
        } });
    }

    const [slug, setSlug] = useState(props.slug ? props.slug : '');
    const [title, setTitle] = useState(props.title ? props.title : '');
    const [content, setContent] = useState(props.content ? props.content : '');

    const customSetState = (e, fieldName) => {
        if (fieldName === 'slug') {
            setSlug(e.target.value);
        } else if (fieldName === 'title') {
            setTitle(e.target.value);
            setSlug(e.target.value.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\d-]/g, ''))
        } else if (fieldName === 'content') {
            setContent(e.target.value);
        }
    } 

    const searchBundleForError = (fieldArray) => {
        let returnError = null;
        //Try to match the given fieldArray with the path of each element in the errorBundle
        props.errorBundle.forEach((errorElement) => {
            if(errorElement.name) {
                console.log('Trying to match', errorElement.name, '&', fieldArray);
            }
            if (errorElement.name && errorElement.name.length === fieldArray.length && errorElement.name.every((element, index) => { return element === fieldArray[index] })) {
                returnError = errorElement.msg;
            }
        });
        return returnError;
    }

    return (
        <FormWrapper onSubmit={handleSubmit}>
            {props.generalErrorMsg &&
                <p>Uh oh: {props.generalErrorMsg}!</p>
            }
            <TextInput
                label='Title'
                type="text"
                name='title'
                error={searchBundleForError(['title'])}
                value={title}
                onChange={e => customSetState(e, 'title')}/>
            <TextInput
                label='Slug'
                type="text"
                name='slug'
                error={searchBundleForError(['slug'])}
                value={slug}
                onChange={e => customSetState(e, 'slug')}/>
            <TextArea
                rows='15'
                label='Content'
                name='content'
                error={searchBundleForError(['content'])}
                value={content}
                onChange={e => customSetState(e, 'content')}/>
            <div id='submit'>
                <SubmitButton
                    btnStyle='orange'
                    text='Submit form'/>
            </div>
        </FormWrapper>
    )
}

export default NewsForm