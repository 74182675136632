import styled from 'styled-components'

export const CompoundHeading = styled.div`
    
    border-bottom: 2px solid #000;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0 0 1rem 0;
    padding-bottom: 0.5rem;

    h1, h2, h3, h4 {
        border: 0;
        display: inline;
        margin: 0 1rem 0 0;
        padding: 0;
    }

    aside {
        display: inline
    }

`;

export const H1 = styled.h1`
    border-bottom: 2px solid #000;
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
    padding-bottom: 0.5rem;
    text-align: center;
`;

export const H2 = styled.h2`
    background-color: #EEE;
    border-radius: 5px;
    font-size: 1.2rem;
    padding: 0 0.5rem;
`;

export const H3 = styled.h3`
    font-size: 1.2rem;
`;

export const H4 = styled.h4`
    font-size: 1rem;
    margin: 0.5rem 0;
`;