import React from 'react'

import { OptionWrapper } from './style'

const Option = (props) => {

    return (
        <OptionWrapper {...props}>
            {props.display}
        </OptionWrapper>
    )

}

export default Option