import { gql } from "apollo-boost";

export const MUTATION_LOGIN_USER = gql`
mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
        token
        user {
            email
        }
    }
}
`;