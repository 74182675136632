import styled from 'styled-components'

export const FormWrapper = styled.form`

    border-top: 1px solid #000;
    padding-top: 1rem;

    label {
        span {
            display: block;
        }

        input, textarea {
            width: 60%;
        }

        display: block;
    }

    .error {
        color: #F00;
        font-size: 0.8rem;
        margin: 0;
    }

    #submit {
        margin: 1rem 0;
        text-align: center;
    }
`;