import React from 'react';

import Nav from '../../../components/Nav'

import { FooterWrapper } from './style';

const Footer = () => {

    const year = new Date().getFullYear();

    const navItems = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'News',
            url: '/news'
        },
        {
            title: 'FAQ',
            url: '/frequently-asked-questions'
        }
    ]

    return (
        <FooterWrapper>
            <Nav
                navItems = {navItems} />
            <small>&#169; The World of WOWs 2007 - {year}</small>
        </FooterWrapper>
    )
}

export default Footer;