import React from 'react'

import { SelectWrapper } from './style'

const Select = (props) => {

    return (
        <label>
            {props.label}&nbsp;
            <SelectWrapper {...props}>
                {props.options}
            </SelectWrapper>
        </label>
    )

}

export default Select