import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import ReactGA from 'react-ga'

import { UserProvider } from './contexts/UserContext'

import AdminDashboard from './views/Admin'
import AdminNewsIndex from './views/Admin/News'
import AdminNewsDetail from './views/Admin/News/detail'
import AdminWowDetail from './views/Admin/Wows/detail'
import AdminWowIndex from './views/Admin/Wows'

import Categories from './views/Categories'
import Category from './views/Category'
import { EraseCookie } from "./helpers/cookie"
import Error from './views/Error'
import FAQs from './views/FAQs'
import Home from './views/Home'
import Layout from './views/Layout'
import Login from './views/Login'
import Logout from './views/Logout'
import News from './views/News'
import ProtectedRoute from './components/ProtectedRoute'
import Redirect from './views/Redirect'
import Search from './views/Search'
import Wow from './views/Wow'

import { QUERY_CURRENT_USER } from './graphql'

ReactGA.initialize('UA-159148752-1');

const App = (props) => {

    useEffect(() => {
        //console.log(props.history.location.pathname);
        ReactGA.pageview(props.history.location.pathname);
    }, [props.history.location.pathname]);

    const [ currentUser, setCurrentUser ] = useState();
    const { loading, error, data } = useQuery(QUERY_CURRENT_USER);

    if (!loading) {
        //If not authenticaed, or no auth token
        if (!data || !data.currentUser || data.currentUser.email === 'guest' || !props.jwtAuthToken) {
            //Delete any user cookie that might exist?
            if (!currentUser) {
                //console.log('Setting current user to', data.currentUser);
                setCurrentUser(data.currentUser);
            }
        } else {
            //if authenticated, and nothing currently in currentUser
            if (!currentUser || currentUser.email === 'guest') {
                //console.log('Setting current user to', data.currentUser);
                setCurrentUser(data.currentUser);
            }
        }
    }
  
    if (error) {
        EraseCookie('jwtAuthToken'); //Delete any user cookie (as they could be invalid for some reason)
        console.log('User error...');
    }

    return (
        <UserProvider value={{currentUser, setCurrentUser}}>
            <Layout>
                <Switch>
                    <ProtectedRoute adminOnly path="/admin/news/:slug" component={AdminNewsDetail} />
                    <ProtectedRoute adminOnly path="/admin/news" component={AdminNewsIndex} />
                    <ProtectedRoute adminOnly path="/admin/wows/:slug" component={AdminWowDetail} />
                    <ProtectedRoute adminOnly path="/admin/wows" component={AdminWowIndex} />
                    <ProtectedRoute adminOnly path="/admin" component={AdminDashboard} />
                    <Route path="/error" component={Error} />
                    <Route path="/search" component={Search} />
                    <Route path="/categories/:slug" component={Category} />
                    <Route path="/categories" component={Categories} />
                    <Route path="/frequently-asked-questions" component={FAQs} />
                    <Route path="/news" component={News} />
                    <Route path="/login" component={Login} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/wows/:slug?" component={Wow} />
                    <Route exact path="/" component={Home} />
                    <Route path="/:slug?" component={Redirect} />
                </Switch>
            </Layout>
        </UserProvider>
    );
}

export default withRouter(App);