import { gql } from "apollo-boost";

export const QUERY_THEME_BY_SLUG = gql`
query ThemeBySlugQuery($slug: String!, $orderBy: String, $direction: String, $cursor: String){
  themeBySlug(slug: $slug){
      slug
      name
      description
      wow_count
      wows(cursor: $cursor, orderBy: $orderBy, direction: $direction) {
        items {
          id
          slug
          title
        }
        cursor
      }
  }
}
`;