import styled from 'styled-components'

export const CategoryWrapper = styled.div`
    h1 {
        margin-top: 0;
    }

    > button {
        margin: 0 auto;
    }

    > aside {
        text-align: left;
    }

`;

export const WowList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0.5rem 0;
    padding: 0;
`

export const Wow = styled.li`
    border: 2px solid #65A21D;
    border-radius: 5px;
    margin: 0.5rem;

    > a {
        color: #000;
        display: inline-block;
        padding: 1rem;
    }

    > a:hover {
        background-color: #65A21D;
        color: #FFF;
    }

`;