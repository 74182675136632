import styled from 'styled-components'

export const CategoriesWrapper = styled.div`
    h1 {
        margin-top: 0;
    }

    > button {
        margin: 0 auto;
    }
`;

export const CategoryList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0.5rem 0;
    padding: 0;
`;

export const CategoryListItem = styled.li`

    > a {
        border-radius: 5px;
        background-color: #65A21D;
        color: #FFF;
        display: inline-block;
        font-weight: bold;
        margin: 0.5rem;
        padding: 1rem;

        span {
            background-color: #FFF;
            border-radius: 50px;
            color: #000;
            font-size: 0.8rem;
            margin-left: 0.5rem;
            padding: 0.4rem;
        }

    }

    > a:hover {
        background-color: #72BA1C;
        color: #000000;
    }

`;