import React from 'react'

import { CompoundHeading, H1, H2, H3, H4 } from './style';

const Heading = (props) => {

    let heading, headingContent;

    switch (props.size) {
        case '1':
            headingContent = <H1>{props.children}</H1>
            break;
        case '2':
            headingContent = <H2>{props.children}</H2>
            break;
        case '3':
            headingContent = <H3>{props.children}</H3>
            break;
        case '4':
            headingContent = <H4>{props.children}</H4>
            break;
        default:
            headingContent = <strong>{props.children}</strong>
            break;
    }

    //if there's any aside data, structure is slightly different
    if (props.aside) {
        heading=<CompoundHeading>{headingContent}<aside>{props.aside}</aside></CompoundHeading>
    } else {
        heading = headingContent
    }

    return heading
}

export default Heading