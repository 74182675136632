import styled from 'styled-components'

export const ButtonWrapper = styled.button(props => {

    //Default (green) styles
    let backgroundColor = 'background: #65A21D;'
    let border = '';
    let borderBottom = ''
    let borderRadius = 'border-radius: 5px;'
    let color = '#FFF'
    let marginBottom = ''
    let padding = props.size === 'small' ? '0.3rem 0.8rem' : '0.5rem 1rem'
    let hoverBackgroundColor = 'background: #72BA1C;'
    let hoverBorderBottom = ''
    let svgFill = '#000'

    if (props.btnStyle === 'text') {
        backgroundColor = 'background: transparent;'
        border = 'border: 0;'
        borderRadius = ''
        color = '#000'
        borderBottom = 'border-bottom: 2px solid transparent;'
        marginBottom = 'margin-bottom: -2px;'
        padding = '0'
        hoverBackgroundColor = ''
        hoverBorderBottom = 'border-bottom: 2px solid #000;'
    }

    if (props.btnStyle === 'menu') {
        padding = '0.5rem'
        svgFill = '#FFF'
    }

    if (props.btnStyle === 'danger') {
        backgroundColor = 'background: #dc1010;'
        hoverBackgroundColor = 'background: #f7a1a1;'
    }

    return (`
        ${backgroundColor}
        ${border}
        ${borderBottom}
        ${borderRadius}
        color: ${color};
        cursor: pointer;
        display: flex;
        font-size: ${props.size === 'small' ? '0.8rem' : '1rem'};
        ${marginBottom}
        padding: ${padding};

        :hover {
            ${hoverBackgroundColor}
            ${hoverBorderBottom}
            color: #000;
        }

        > span {
            margin: 0 0.3rem;
        }

        > svg > path {
            fill: ${svgFill};
        }

    `)

})