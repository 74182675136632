import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import ButtonLink from '../../../components/ButtonLink'
import Error from '../../../components/Error'
import Heading from '../../../components/Heading'
import Loading from '../../../components/Loading'

import { QUERY_ALL_WOWS } from './graphql'

import { IndexWrapper } from '../style'

const Wow = () => {

    const { loading, error, data } = useQuery(QUERY_ALL_WOWS);

    let allWows;

    if (loading) {
        allWows = <Loading />
    }
    if (error) {
        allWows= <Error />
    }
    if (data) {
        allWows = data.wows.map(wow => {
            return <div key={wow.id}>
                <span>{wow.id}</span>
                <span>{wow.title}</span>
                <span><Link to={`/admin/wows/${wow.slug}`}>Edit</Link></span>
            </div>
        })
    }

    return (
        <IndexWrapper>
            <Heading size='1'>Index of all WOWs</Heading>
            <div id='createNew'>
                <ButtonLink to='/admin/wows/new'>Create new WOW</ButtonLink>
            </div>
            <div id='itemList'>
                {allWows}
            </div>
        </IndexWrapper>
    )
}

export default Wow