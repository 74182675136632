import { gql } from "apollo-boost";

export const QUERY_THEMES = gql`
query ThemesQuery($cursor: String, $orderBy: String, $direction: String){
  themes(cursor: $cursor orderBy: $orderBy direction: $direction){
      cursor
      theme_count
      themes {
        id
        slug
        name
        wow_count
      }
  }
}
`;