import React from 'react'

import { LoadingWrapper } from './style'

const Loading = () => {

    return (
        <LoadingWrapper>Loading...</LoadingWrapper>
    )

}

export default Loading