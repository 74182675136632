import React from 'react'

import Heading from '../../components/Heading'

import { NewsItemWrapper } from './style'

const NewsItem = (props) => {

    /*
     * If on the homepage, heading size 3, else heading size 2
     */

    const newsHTMLContent = { __html: props.content }

    return (
        <NewsItemWrapper>
            <Heading size={props.headingSize === '4' ? '4' : '3'}>{props.title}</Heading>
            <p dangerouslySetInnerHTML={newsHTMLContent} />
        </NewsItemWrapper>
    )

}

export default NewsItem