import styled from 'styled-components'

export const IndexWrapper = styled.div`
    text-align: left;

    #createNew {
        text-align: center;
        margin: 1rem 0;
    }

    #itemList {
        > div {
            display: flex;
            flex-direction: row;
            
            span:nth-child(1) {
                width: 5rem;
            }

            span:nth-child(2) {
                flex-grow: 2;
            }
        }
    }
`;