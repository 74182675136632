import React, { useEffect, useRef, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import BreadcrumbTopper from '../../components/BreadcrumbTopper'
import Button from '../../components/FormComponents/Button'
import Error from '../../components/Error'
import Heading from '../../components/Heading'
import Loading from '../../components/Loading'
import FilterForm from '../../components/FilterForm'

import { QUERY_THEME_BY_SLUG } from './graphql';

import { CategoryWrapper, WowList, Wow } from './style'

const Category = (props) => {

    const [ orderBy, setOrderBy ] = useState('title');
    const [ direction, setDirection ] = useState('ASC');
    const [ showFilters, setShowFilters ] = useState(false);

    const prevScrollY = useRef(0);

    let showMoreResultsButton = true;

    const handleScroll = () => {
        const currentScrollY = window.scrollY
        if (prevScrollY.current < currentScrollY) {
            //If screen pos is at the bottom, trigger fetchMore
            //console.log('DOWN', currentScrollY + window.innerHeight, document.body.clientHeight);
            if (currentScrollY + window.innerHeight >= document.body.clientHeight) {
                //console.log(loading, data);
                if (!loading && data) {
                    if (data.themeBySlug.wows.cursor) {
                        onLoadMore();
                    }
                }
            }

        }
        prevScrollY.current = currentScrollY;
    }

    const handleOptionUpdate = (event) => {
        switch (event.target.name) {
            case 'direction':
                setDirection(event.target.value)
                break
            case 'orderBy':
                setOrderBy(event.target.value)
                break
            default:
                break
        }
    }

    const handleToggleFilter = () => {
        setShowFilters(!showFilters);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    })

    const { loading, error, data, fetchMore } = useQuery(QUERY_THEME_BY_SLUG, {
        notifyOnNetworkStatusChange: true,
        variables: {
            slug: props.match.params.slug,
            orderBy: orderBy,
            direction: direction
        },
    });

    let categoryContent;
    let categoryStatus;
    let categoryDescription;

    const onLoadMore = () => {
        return fetchMore({
            query: QUERY_THEME_BY_SLUG,
            notifyOnNetworkStatusChange: true,
            variables: {
                cursor: data.themeBySlug.wows.cursor,
                slug: props.match.params.slug,
                orderBy: orderBy,
                direction: direction
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {

                //console.log('fetch', fetchMoreResult);

                const newResults = fetchMoreResult.themeBySlug.wows.items;
                const newCursor = fetchMoreResult.themeBySlug.wows.cursor;

                let displayWows;
                //If previous orderBy or direction are different, start list again
                if (previousResult.themeBySlug.orderBy === fetchMoreResult.themeBySlug.orderBy &&
                    previousResult.themeBySlug.direction === fetchMoreResult.themeBySlug.direction) {
                        //console.log('Add new to existing');
                        displayWows = [...previousResult.themeBySlug.wows.items, ...newResults]
                    } else {
                        //console.log('Display only new');
                        displayWows = newResults
                    }

                return {
                    themeBySlug: {
                        name: previousResult.themeBySlug.name,
                        slug: previousResult.themeBySlug.slug,
                        description: previousResult.themeBySlug.description,
                        wow_count: previousResult.themeBySlug.wow_count,
                        wows: {
                            items: displayWows,
                            cursor: newCursor,
                            __typename: previousResult.themeBySlug.wows.__typename,
                        },
                        __typename: previousResult.themeBySlug.__typename,
                    },
                }
            }
        })
    }

    if (loading) {
        categoryStatus = <Loading />
    }
    if (error) {
        categoryStatus= <Error />
    }
    if (data) {
        //console.log('Data received');

        //Hide the 'load more' button if there's no more data
        if (!data.themeBySlug || data.themeBySlug.wows.items.length === data.themeBySlug.wow_count) {
            //console.log('No more WOWs to display');
            showMoreResultsButton = false;
        }

        if (data.themeBySlug) { //If the category exists

            const wowContent = data.themeBySlug.wows.items.map(wow => {
                return <Wow key={wow.id}>
                    <Link to={`/wows/${wow.slug}`}>
                        {wow.title}
                    </Link>
                </Wow>
            })



            categoryDescription = { __html: data.themeBySlug.description }

            categoryContent = <>
                <Heading size='1' aside={ data ? `(contains ${data.themeBySlug.wow_count} WOWs)` : null}>{data.themeBySlug.name}</Heading>
                <aside dangerouslySetInnerHTML={categoryDescription} />
                <WowList>
                    {wowContent}
                </WowList>
            </>

        } else {

            //Redirect to error page
            return <Redirect to={`/error`} />

        }

    }

    return (
        <CategoryWrapper>
            <BreadcrumbTopper
                rhs={
                    <Button
                        size='small'
                        btnStyle='text'
                        text={`${showFilters ? 'Hide' : 'Show'} filter`}
                        onClick={handleToggleFilter} />
                }/>
            <FilterForm
                showForm={showFilters}
                fields={[
                    {
                        name: 'orderBy',
                        type: 'select',
                        value: orderBy,
                        label: 'Order by',
                        options: [
                            {
                                value: 'title',
                                display: 'WOW title'
                            }
                        ]
                    },
                    { 
                        name: 'direction',
                        type: 'select',
                        value: direction,
                        options: [
                            {
                                value: 'ASC',
                                display: 'A-Z'
                            },
                            {
                                value: 'DESC',
                                display: 'Z-A'
                            }
                        ]
                    }
                ]}
                onOptionUpdate={event => handleOptionUpdate(event)} />
            {categoryContent}
            {categoryStatus}
            { !loading && showMoreResultsButton &&
                <Button
                    text='Load more WOWs'
                    onClick={onLoadMore} />
            }
        </CategoryWrapper>
    )



}

export default Category