import styled from 'styled-components'

export const FooterWrapper = styled.footer`
    display: flex;
    flex-direction: column;

    border-radius: 5px;
    background-color: #65A21D;
    padding: 1rem;

    > nav > ul {
        display: flex;
        justify-content: center;

        li {
            margin: 0 0.5rem;
        }

    }

    > small {
        font-size: 0.8rem;
        margin: 1rem 0 0 0;
    }

`;