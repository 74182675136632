import React, { useState } from 'react'

import Heading from '../../components/Heading'
import Loading from '../../components/Loading'
import SubmitButton from '../../components/FormComponents/SubmitButton'
import TextInput from '../../components/FormComponents/TextInput'

import { LoginWrapper } from './style'

const LoginForm = props => {

    const handleSubmit = event => {
        event.preventDefault();

        props.login({ variables: {
            email,
            password
        } });
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    //If fields are modified after validation failure, clear validation error
    const customSetState = (e, fieldName) => {
        props.fieldErrors[fieldName] = null;

        if (fieldName === 'email') {
            setEmail(e.target.value);
        }
        if (fieldName === 'password') {
            setPassword(e.target.value);
        }
    }

    return <LoginWrapper>
        <Heading size='1'>Login</Heading>
        <form onSubmit={handleSubmit}>
            <TextInput
                label="Email:"
                value={email}
                onChange={e => customSetState(e, 'email')}
                type="text"
                name="email"
                //helper="Helper text"
                error={props.fieldErrors.email ? props.fieldErrors.email.message : null}
            />

            <TextInput
                label="Password:"
                value={password}
                onChange={e => customSetState(e, 'password')}
                type="password"
                name="password"
                // helper="Fill it out"
                error={props.fieldErrors.password ? props.fieldErrors.password.message : null}
            />

            {/* <Link to="/account/forgotten-password">
                Forgotten password?
            </Link> */}

            <SubmitButton
                text='Login' />

            { props.loading &&
                <Loading />
            }

            {props.fieldErrors.overallError ? props.fieldErrors.overallError.message : null}

        </form>

        {/* <p>No account? <Link to="/account/new-account">Register a new account</Link></p> */}

    </LoginWrapper>
}

export default LoginForm;