import React from 'react'
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks'

import ButtonLink from '../../components/ButtonLink'
import Error from '../../components/Error'
import Heading from '../../components/Heading'
import Loading from '../../components/Loading'
import NewsItem from '../News/newsItem'

import { HomeWrapper, HomeLower, WowList, WowListItem } from './style'

import { QUERY_HOME } from './graphql'

/*
 * GraphQL call for surveys? Implement much later...
*/

const Home = () => {

    const { loading, error, data } = useQuery(QUERY_HOME, {
        variables: {
            newsLimit: 5,
            wowLimit: 10
        }
    });

    let newsContent, wowContent;

    if (loading) {
        newsContent = <Loading />
        wowContent = <Loading />
    }
    if (error) {
        newsContent = <Error />
        wowContent = <Error />
    }
    if (data) {
        newsContent = data.news.items.map(newsItem => {
            return <NewsItem
                key={newsItem.id}
                headingSize='4'
                title={newsItem.title}
                content={newsItem.content} />
        })

        const wowListItems = data.wows.map(wow => {
            return <WowListItem key={wow.id}>
                    <Link to={`/wows/${wow.slug}`}>{wow.title}</Link>
                </WowListItem>
        })

        wowContent = <WowList>{wowListItems}</WowList>
    }

    return (
        <HomeWrapper>
            <Heading
                size='1'>
                Welcome to the World of WOWs!
            </Heading>
            <p>The World of WOWs is a collection of records and facts like no other. Is it interesting? Is it unique? Then it's WOW-worthy!</p>
            <p>What is the highest mountain in the world?<br />
            Is it Mount Everest? It's Mount Everest, isn't it? Where is Mount Everest?</p>
            <p>Shut up about Mount Everest! Such records belong in more traditional record compilations: those involving facts and proof. Facts and proof have a place in The World of WOWs, but a place situated squarely alongside that of non-existent and entirely imaginary records.</p>
            <p>Yes, The World of WOWs is the sole repository that holds the un-provable and theoretical in equal esteem to physical, tangible achievements. Whatever your WOW is, will, or might be, its home is here.</p>
            {/* <p>Submit your WOW now, and shake Dr. Immortality by the hand. You'll receive an official certificate to print out and look at!</p> */}
            <HomeLower>
                <section>
                    <Heading
                        size='2'>
                        Newest WOWs
                    </Heading>
                    {wowContent}
                </section>
                <section>
                    <Heading
                        size='2'>
                        News
                    </Heading>
                    {newsContent}
                    <div>
                        <ButtonLink
                            to='/news'>
                            See all news
                        </ButtonLink>
                    </div>
                </section>
            </HomeLower>
        </HomeWrapper>
    )
}

export default Home