import styled from 'styled-components'
import { device } from '../../helpers/device'

export const LoginWrapper = styled.div`

    form {
        display: flex;
        flex-direction: column;
        margin: 0 auto;

        > label > span {
            display: inline-block;
            min-width: 5rem;
            text-align: left;
        }

        .helper-text {
            margin-bottom: 0.5rem;
        }

        @media ${device.tablet} {
            max-width: 50%;
        }

    }

`;