import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'

import Button from '../../../../components/FormComponents/Button'
import Error from '../../../../components/Error'
import Loading from '../../../../components/Loading'
import Option from '../../../../components/FormComponents/Select/option'
import TextArea from '../../../../components/FormComponents/TextArea'
import TextInput from '../../../../components/FormComponents/TextInput'
import Select from '../../../../components/FormComponents/Select'

import { QUERY_SEARCH_HOLDERS, QUERY_HOLDER_BY_SLUG } from './graphql'

import { AddHolder } from './style'

const HoldersForm = (props) => {

    const holderInfoObject = {
        value: '',
        description: '',
        held_at: new Date().toISOString().slice(0, 19).replace('T', ' ')
    }
    const holderObject = {
        slug: '',
        name: '',
        description: '',
        info: holderInfoObject
    }

    const [ q, setQ ] = useState('')
    const [ holder, setHolder ] = useState(holderObject)
    const [ holderPicked, setHolderPicked ] = useState('default-option')
    const [ getHolders, { loading, error, data } ] = useLazyQuery(QUERY_SEARCH_HOLDERS)
    const [ getHolderBySlug, { loading: holderLoading, error: holderError, data: holderData } ] = useLazyQuery(QUERY_HOLDER_BY_SLUG)

    /*
     * lastFormAction used to keep track of current form status dut to multiple queries / data / states
     * 0 = Nothing
     * 1 = Searched for holders
     * 2 = Picked holder
     * 3 = Edited text field 
     */
    const [ lastFormAction, setLastFormAction ] = useState(0);

    let holderSelect

    if (loading) {
        holderSelect = <Loading />
    }
    if (error) {
        holderSelect = <Error />
    }
    if (data) {
        //console.log('holders query run');

        let options = data.holders.map(holder => {
            return <Option
                key={holder.slug}
                value={holder.slug}
                display={holder.name}/>
        })

        options.unshift(<Option key='default-option' value='default-option' display='Choose a holder' disabled={true}/>)

        holderSelect = <Select
            label='Find an existing holder'
            options={options}
            value={holderPicked}
            onChange={e => handlePickHolder(e)}/>
    }

    const handleSetQuery = (e) => {
        setQ(e.target.value);
        setLastFormAction(1)

        if (e.target.value.length >= 3) {
            getHolders({ variables: { q: e.target.value } })
        }
    }

    const handlePickHolder = (e) => {
        //console.log('handlePickHolder');
        setHolderPicked(e.target.value)
        setLastFormAction(2)
        getHolderBySlug({ variables: { slug: e.target.value } })
    }

    const handleInputChange = (parent = null, e) => {
        setLastFormAction(3);

        const { name, value } = e.target
        //console.log('Try to set holder:', name, value, parent)
        setHolder(prevState => {
            const stateDup = {...prevState} //Duplicate prevState (so we aren't mutating it directly)
            if(parent) {
                stateDup[parent][name] = value //Update the given property
            } else {
                stateDup[name] = value //Update the given property
                if(name === 'name') {
                    stateDup['slug'] = value.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\d-]/g, '')
                }
            }
            return stateDup //Set state using the duplicate
        })
    }

    if (holderLoading) {
        holderSelect = <Loading />
    }
    if (holderError) {
        holderSelect = <Error />
    }
    if (holderData) { //Need to make sure this only runs once
        //console.log('Update holder state?', holder.name, ' & ', holderData.holderBySlug.name)
        if (holder.name !== holderData.holderBySlug.name && lastFormAction !== 3) { //Make sure this isn't when the text fields are updated, as it will overwrite
            //console.log('name doesnt match, setting');
            setHolder(props.remapHolder(holderData.holderBySlug))
        }
    }

    //console.log('Holder data is now', holder);

    return (
        <AddHolder>
            <p>Add a holder:</p>
            <TextInput
                label='Search for holder'
                type="text"
                name='holder_search'
                value={q}
                onChange={e => handleSetQuery(e)}/>

                {holderSelect &&
                    holderSelect}

                {/* OnClick, add content to text boxes and make un-editable (changing the slug would cause a new record to be created) */}

            <div id='holder'>
                <TextInput
                    label='Name'
                    type="text"
                    name='name'
                    value={holder.name}
                    onChange={e => handleInputChange(null, e)}
                    />
                <TextInput
                    label='Slug'
                    type="text"
                    name='slug'
                    value={holder.slug}
                    onChange={e => handleInputChange(null, e)}/>
                <TextArea
                    rows='2'
                    label='Description'
                    type="text"
                    name='description'
                    value={holder.description}
                    onChange={e => handleInputChange(null, e)}/>
                <aside>
                    <p>Info data relates specifically to the holder's WOW achievement, rather than being generic to the holder.</p>
                    <TextInput
                        label='Info value'
                        type="text"
                        name='value'
                        value={holder.info.value}
                        onChange={e => handleInputChange('info', e)}/>
                    <TextArea
                        rows='2'
                        label='Info description'
                        type="text"
                        name='description'
                        value={holder.info.description}
                        onChange={e => handleInputChange('info', e)}/>
                    <TextInput
                        label='Info held at date'
                        type="text"
                        name='held_at'
                        value={holder.info.held_at}
                        placeholder='yyyy-mm-dd hh:mm:ss'
                        onChange={e => handleInputChange('info', e)}/>
                </aside>
                <Button
                    text='Add this holder to WOW'
                    onClick={e => props.handleAddNewHolderToState(e, holder)}/>
            </div>

        </AddHolder>
    )
}

export default HoldersForm