import styled from 'styled-components'

export const AddHolder = styled.div`
    border: 1px solid #000;
    padding: 1rem;

    p {
        margin: 0 0 0.5rem 0;
    }

    #theme {
        border-top: 1px solid #000;
        aside {
            display: none
        }
    }
`;