import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'

import BreadcrumbTopper from '../../components/BreadcrumbTopper'
import Button from '../../components/FormComponents/Button'
import Error from '../../components/Error'
import Heading from '../../components/Heading'
import Loading from '../../components/Loading'
import FilterForm from '../../components/FilterForm'

import { QUERY_THEMES } from './graphql'

import { CategoriesWrapper, CategoryList, CategoryListItem } from './style'

/*
 * Categories can be ordered by their WOW count, or alphabetically & direction can be reversed
 * Infinite scroll
 */

const Categories = () => {

    const [ orderBy, setOrderBy ] = useState('wow');
    const [ direction, setDirection ] = useState('DESC');
    const [ showFilters, setShowFilters ] = useState(false);

    const prevScrollY = useRef(0);

    let showMoreResultsButton = true;

    const handleScroll = () => {
        const currentScrollY = window.scrollY
        if (prevScrollY.current < currentScrollY) {
            //If screen pos is at the bottom, trigger fetchMore
            //console.log('DOWN', currentScrollY + window.innerHeight, document.body.clientHeight);
            if (currentScrollY + window.innerHeight >= document.body.clientHeight) {
                //console.log(loading, data);
                if (!loading && data) {
                    if (data.themes.cursor) {
                        onLoadMore();
                    }
                }
            }

        }
        prevScrollY.current = currentScrollY;
    }

    const handleOptionUpdate = (event) => {
        switch (event.target.name) {
            case 'direction':
                setDirection(event.target.value)
                break
            case 'orderBy':
                setOrderBy(event.target.value)
                break
            default:
                break
        }
    }

    const handleToggleFilter = () => {
        setShowFilters(!showFilters);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    })

    const { loading, error, data, fetchMore } = useQuery(QUERY_THEMES, {
        notifyOnNetworkStatusChange: true,
        variables: {
            orderBy: orderBy,
            direction: direction
        },
    });

    let themesContent;
    let themesStatus;

    const onLoadMore = () => {
        return fetchMore({
            query: QUERY_THEMES,
            notifyOnNetworkStatusChange: true,
            variables: {
                cursor: data.themes.cursor,
                orderBy: orderBy,
                direction: direction
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newResults = fetchMoreResult.themes.themes;
                const newCursor = fetchMoreResult.themes.cursor;

                let displayThemes;
                //If previous orderBy or direction are different, start list again
                if (previousResult.themes.orderBy === fetchMoreResult.themes.orderBy &&
                    previousResult.themes.direction === fetchMoreResult.themes.direction) {
                        //console.log('Add new to existing');
                        displayThemes = [...previousResult.themes.themes, ...newResults]
                    } else {
                        //console.log('Display only new');
                        displayThemes = newResults
                    }

                return {
                    themes: {
                        themes: displayThemes,
                        theme_count: fetchMoreResult.themes.theme_count,
                        cursor: newCursor,
                        __typename: previousResult.themes.__typename,
                    },
                }
            }
        })
    }



    if (loading) {
        //console.log('Loading...');
        themesStatus = <Loading />
    }
    if (error) {
        themesStatus= <Error />
    }
    if (data) {

        //Hide the 'load more' button if there's no more data
        if (!data.themes || data.themes.themes.length === data.themes.theme_count) {
            console.log('No more Themes to display');
            showMoreResultsButton = false;
        }

        const themesItems = data.themes.themes.map(theme => {
            return <CategoryListItem key={theme.id}>
                    <Link to={`/categories/${theme.slug}`}>
                        {theme.name}
                        <span>{theme.wow_count}</span>
                    </Link>
                </CategoryListItem>
        })

        themesContent = <CategoryList>
            {themesItems}
        </CategoryList>

    }

    let directionFilterOptions = [{
            value: 'DESC',
            display: 'High-Low'
        },
        {
            value: 'ASC',
            display: 'Low-High'
        }
    ]

    if (orderBy === 'alpha') {
        directionFilterOptions = [
        {
            value: 'ASC',
            display: 'A-Z'
        },
        {
            value: 'DESC',
            display: 'Z-A'
        }]
    }

    return (
        <CategoriesWrapper>
            <BreadcrumbTopper
                rhs={
                    <Button
                        size='small'
                        btnStyle='text'
                        text={`${showFilters ? 'Hide' : 'Show'} filter`}
                        onClick={handleToggleFilter} />
                }/>
            <FilterForm
                showForm={showFilters}
                fields={[
                    {
                        name: 'orderBy',
                        type: 'select',
                        value: orderBy,
                        label: 'Order by',
                        options: [
                            {
                                value: 'wow',
                                display: 'WOW count'
                            },
                            {
                                value: 'alpha',
                                display: 'Category title'
                            }
                        ]
                    },
                    { 
                        name: 'direction',
                        type: 'select',
                        value: direction,
                        options: directionFilterOptions
                    }
                ]}
                onOptionUpdate={event => handleOptionUpdate(event)} />
            <Heading size='1'>Categories</Heading>
            {themesContent}
            {themesStatus}
            { !loading && showMoreResultsButton &&
                <Button
                    text='Load more categories'
                    onClick={onLoadMore} />
            }
        </CategoriesWrapper>
    )
}

export default Categories