import { gql } from "apollo-boost";

export const MUTATION_UPLOAD_MEDIA = gql`
mutation MediaMutation(
        $files: [File]!
    ) {
        mediaSignedUrlRequest(
        files: $files
    ) {
        name
        url
    }
}
`;

export const MUTATION_UPDATE_MEDIA = gql`
mutation MediaMutation(
        $media: [MediaInput]!
    ) {
    upsertMedia(
        media: $media
    ) {
        url
        file_type
        title
        caption
    }
}
`;