import styled from 'styled-components'
import { device } from '../../helpers/device'

export const LogoWrapper = styled.div`

    color: #65A21D;
    font-size: 1.5rem;
    font-weight: bold;

    span:nth-child(1) {
        display: none;
    }

    @media ${device.tablet} {

        span:nth-child(1) {
            display: inline;
        }

        span:nth-child(2) {
            display: none;
        }

    }

`;