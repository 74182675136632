import React from 'react'

import Option from '../FormComponents/Select/option'
import Select from '../FormComponents/Select'
import TextInput from '../FormComponents/TextInput'

import { FormWrapper } from './style'

const FilterForm = (props) => {

    const fields = props.fields.map((field, index) => {
        let fieldContent

        switch (field.type) {
            case "select":
                const options = field.options.map((option, index) => {
                    return <Option key={index} value={option.value} display={option.display}/>
                });
                fieldContent = <Select key={index} name={field.name} value={field.value} onChange={props.onOptionUpdate} label={field.label} options={options}/>
                break
            default: //Default is a text input
                fieldContent = <TextInput key={index} type='text' name={field.name} value={field.value} onChange={props.onOptionUpdate } />
                break
        }
        return fieldContent
    })

    return (
        <FormWrapper showForm={props.showForm}>
            <div>
                {fields}
                { props.searchField &&
                    <label>
                    Search for&nbsp;
                    <TextInput
                        type="text"
                        name={props.searchField.name}
                        value={props.searchField.value}
                        placeholder='Search for a WOW...'
                        onChange={props.onOptionUpdate} />
                    </label>
                }
            </div>
        </FormWrapper>
    )

}

export default FilterForm