import styled from 'styled-components'

export const FormWrapper = styled.form`

    background-color: #EEE;
    border-radius: 5px;
    display: ${props => props.showForm === true ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: center;
    margin: 0.5rem 0;
    padding: 0.5rem;

    div {
        display: flex;
        justify-content: center;

        > label {
            margin: 0 0.5rem;
        }

    }
`;