import React, { useState } from 'react'

import Media from '../Media'

import { MediaWrapper } from './style'

const MediaGallery = (props) => {

    //Assume there is at least one image to use

    const [currentImage, setCurrentImage] = useState(props.items[0]); //Put first image into state

    let thumbs

    //If there's more than one image, we need the vertical thumbnails
    if (props.items.length > 1) {
        const thumbContent = props.items.map((thumbItem, index) => {
            return <div
                key={index}
                style={{backgroundImage: `url('https://assets.worldofwows.com/media/${thumbItem.url}')`}}
                onClick={e => handleChangeCurrentImage(thumbItem, e)}></div>
        })
        thumbs = <aside id='thumbs'>{thumbContent}</aside>
    }

    const handleChangeCurrentImage = (thumbItem, e) => {
        e.preventDefault();
        setCurrentImage(thumbItem);
    }
    
    return <MediaWrapper itemCount={props.items.length}>
        <Media
            src={`https://assets.worldofwows.com/media/${currentImage.url}`}
            alt={currentImage.title}
            title={currentImage.title}
            caption={currentImage.caption} />
        {thumbs}
    </MediaWrapper>

}

export default MediaGallery