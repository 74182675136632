import React from 'react'

import { LinkItem } from './style';

const ButtonLink = (props) => {

    return (
    <LinkItem to={props.to} size={props.size}>
        {props.children}
    </LinkItem>
    )

}

export default ButtonLink