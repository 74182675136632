import { gql } from "apollo-boost";

export const QUERY_HOME = gql`
query HomeQuery($newsLimit: Int, $wowLimit: Int){
  news(limit: $newsLimit){
      items {
        id
        title
        content
      }
  },
  wows(limit: $wowLimit){
      id
      title
      slug
  }
}
`;