import React, { useEffect, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'

import BreadcrumbTopper from '../../components/BreadcrumbTopper'
import Button from '../../components/FormComponents/Button'
import Error from '../../components/Error'
import Heading from '../../components/Heading'
import Loading from '../../components/Loading'
import NewsItem from './newsItem'

import { QUERY_NEWS } from './graphql';

import { NewsWrapper } from './style'

const News = () => {

    const prevScrollY = useRef(0);

    let showMoreResultsButton = true;

    const handleScroll = () => {
        const currentScrollY = window.scrollY
        if (prevScrollY.current < currentScrollY) {
            //If screen pos is at the bottom, trigger fetchMore
            if (currentScrollY + window.innerHeight >= document.body.clientHeight) {
                //console.log(loading, data);
                if (!loading && data) {
                    if (data.news.cursor) {
                        onLoadMore();
                    }
                }
            }

        }
        prevScrollY.current = currentScrollY;
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    })

    const { loading, error, data, fetchMore } = useQuery(QUERY_NEWS, {
        notifyOnNetworkStatusChange: true
    });

    let newsContent;
    let newsStatus;

    const onLoadMore = () => {
        return fetchMore({
            query: QUERY_NEWS,
            notifyOnNetworkStatusChange: true,
            variables: {
                cursor: data.news.cursor
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newResults = fetchMoreResult.news.items;
                const newCursor = fetchMoreResult.news.cursor;

                return {
                    news: {
                        items: [...previousResult.news.items, ...newResults],
                        cursor: newCursor,
                        news_count: previousResult.news.news_count,
                        __typename: previousResult.news.__typename,
                    },
                }
            }
        })
    }

    if (loading) {
        //console.log('Loading...');
        newsStatus = <Loading />
    }
    if (error) {
        newsStatus= <Error />
    }
    if (data) {

        //Hide the 'load more' button if there's no more data
        if (!data.news || data.news.items.length === data.news.news_count) {
            showMoreResultsButton = false;
        }

        newsContent = data.news.items.map(news => {
            return <NewsItem
                key={news.id}
                title={news.title}
                content={news.content} />
        })
    }

    return (
        <NewsWrapper>
            <BreadcrumbTopper />
            <Heading
                size='1'>
                News
            </Heading>
            {newsContent}
            {newsStatus}
            { !loading && showMoreResultsButton &&
                <Button
                    text='Load more news'
                    onClick={onLoadMore} />
            }
        </NewsWrapper>
    )

}

export default News