import styled from 'styled-components'
//import { device } from '../../helpers/device'

export const MediaWrapper = styled.div`

    display: flex;
    flex-direction: column;

    img {
        border: 2px solid #72BA1C;
        border-radius: 10px 10px 0 0;
        width: 100%;
        height: auto;
    }

    p {
        background-color: #DBEAC8;
        border: 2px solid #72BA1C;
        border-top: 0;
        border-radius: 0 0 10px 10px;
        font-size: 0.9rem;
        text-align: center;
        margin: 0;
        padding: 0.5rem 0;
        width: 100%;
    }

`;