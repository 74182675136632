import { gql } from "apollo-boost";

export const QUERY_NEWS = gql`
query NewsQuery($cursor: String){
  news(cursor: $cursor){
      cursor
      items {
        id
        title
        content
      }
      news_count
  }
}
`;