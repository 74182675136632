import { gql } from "apollo-boost";

export const QUERY_SEARCH_HOLDERS = gql`
query Holders($q: String){
    holders(q: $q) {
        slug
        name
    }
}
`;

export const QUERY_HOLDER_BY_SLUG = gql`
query HolderBySlug($slug: String!){
    holderBySlug(slug: $slug) {
        slug
        name
        description
        info {
            value
            description
            held_at
        }
    }
}
`;