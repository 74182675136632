import React from 'react';

import Back from './icons/Back'
import Burger from './icons/Burger'
import Search from './icons/Search'

const Icon = (props) => {
  switch (props.name) {
    case 'back':
        return <Back {...props} />
    case 'burger':
      return <Burger {...props} />
    case 'search':
      return <Search {...props} />
    default:
      return;
  }
}

export default Icon;