import styled from 'styled-components'

export const SelectWrapper = styled.select`
    border: 2px solid #72BA1C;
    border-radius: 100px;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8rem;
    padding: 3px 10px;
    background: #FFFFFF;
`;

export const OptionWrapper = styled.option`

`;