import { gql } from "apollo-boost";

export const QUERY_ALL_WOWS = gql`
query WowQuery{
  wows{
      id
      title
      slug
  }
}
`;

export const QUERY_WOW_BY_SLUG = gql`
query WowQuery($slug: String!){
  wowBySlug(slug: $slug){
      slug
      title
      content
      holder_intro
      holder_template
      holders {
        slug
        name
        description
          info {
            value
            description
            held_at
        }
      }
      themes {
        slug
        name
        description
      }
      media {
        title
        caption
        file_type
        url
      }
      hidden
  }
}
`;

export const MUTATION_UPDATE_WOW = gql`
mutation WowMutation(
        $slug: String!,
        $title: String!,
        $content: String!,
        $hidden: Boolean,
        $holder_intro: String,
        $holder_template: String,
        $holders: [HolderInput]!,
        $themes: [ThemeInput],
        $media: [MediaInput],
        $user: UserInput
    ) {
    upsertWow(
        slug: $slug
        title: $title,
        content: $content,
        hidden: $hidden,
        holder_intro: $holder_intro,
        holder_template: $holder_template,
        holders: $holders,
        themes: $themes,
        media: $media,
        user: $user
    ) {
        slug
        title
    }
}
`;