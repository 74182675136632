import styled from 'styled-components'

export const Button = styled.button(props => {

    let background = '#65A21D' //Default green background
    let backgroundHover = '#72BA1C'

    if (props.btnStyle === 'orange') {
        background = '#eca00f'
        backgroundHover = '#f9c563'
    }

    return (`
        background: ${background};
        border-radius: 5px;
        color: #FFF;
        display: inline-block;
        font-size: ${props.size === 'small' ? '0.8rem' : '1rem'};
        padding: ${props.size === 'small' ? (props.iconOnly ? '0.3rem 0.5rem' : '0.3rem 0.8rem') : '0.5rem 1rem'};
        cursor: pointer;

        > svg > path {
            fill: #FFF;
        }

        :hover {
            background-color: ${backgroundHover};
            color: #000;

            > svg > path {
                fill: #000;
            }
        }
    `)
})