import { gql } from "apollo-boost";

export const QUERY_SEARCH_THEMES = gql`
query Themes($q: String){
    themes(q: $q, direction: "ASC", limit: 100) {
        themes {
            slug
            name
        }
    }
}
`;

export const QUERY_THEME_BY_SLUG = gql`
query ThemeBySlug($slug: String!){
    themeBySlug(slug: $slug) {
        slug
        name
        description
    }
}
`;