import React from 'react'

const Error = () => {
    return (
        <div>
            <h1>Error</h1>
            <p>Hmmm... maybe that doesn't exist?</p>
        </div>

    )
}

export default Error