import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import Button from '../../../components/FormComponents/Button'
import ButtonLink from '../../../components/ButtonLink'
import Logo from '../../../components/Logo'
import SubmitButton from '../../../components/FormComponents/SubmitButton'
import TextInput from '../../../components/FormComponents/TextInput'

import { QUERY_RANDOM_WOW } from './graphql'

import { HeaderWrapper } from './style'

const Header = () => {

    //Eventually use the menu button for the login / user account link, abolish state, menu collpase etc

    const [ menuOpen, setMenuOpen ] = useState(true)

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen)
    }

    const { loading, error, data } = useQuery(QUERY_RANDOM_WOW);

    let randomWow;

    if (loading || error) {
        randomWow = null
    }
    if (data) {
        //Truncate WOW title if too long
        randomWow = <Link to={`/wows/${data.wowByRandom.slug}`}>{data.wowByRandom.title}</Link>
    }

    return (
        <HeaderWrapper>
            <div className='top'>
                <Link to='/'><Logo /></Link>
                <div>
                    { randomWow &&
                        <aside><span>Random:</span> {randomWow}</aside>
                    }
                    <div>
                        <ButtonLink to='/categories' size='small'>Browse categories</ButtonLink>
                        <span>or</span>
                        <form className='header_search' action='/search'>
                            <TextInput
                                type='text'
                                placeholder='Search for a WOW...'
                                name='q' />
                            <SubmitButton size='small' icon='search' iconOnly={true} ariaLabel="Search" />
                        </form>
                    </div>
                </div>
                <Button onClick={handleMenuClick} icon='burger' btnStyle='menu'/>
            </div>
            { menuOpen &&
            <div className='bottom'>
                <ButtonLink to='/categories' size='small'>Categories</ButtonLink>
                <form className='header_search' action='/search'>
                    <TextInput
                        type='text'
                        placeholder='Search for a WOW...'
                        name='q' />
                    <SubmitButton size='small' icon='search' iconOnly={true} ariaLabel="Search" />
                </form>
            </div>
            }
        </HeaderWrapper>
    )
}

export default Header;