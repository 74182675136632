import styled from 'styled-components'

export const ListWrapper = styled.li`

    a {
        color: ${props => props.linkStyle === 'button' ? '#000' : '#FFF'};
    }

    > a:hover {
        border-bottom: 2px solid #000;
    }

`;