import { gql } from "apollo-boost";

export const QUERY_SEARCH = gql`
query Search($q: String!, $cursor: String, $orderBy: String, $direction: String){
    search(q: $q, cursor: $cursor, orderBy: $orderBy, direction: $direction) {
        cursor
        result_count
        wows {
            id
            slug
            title
        }
    }
}
`;